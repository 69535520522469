<template>
  <div>
    <hero-bar>
      Settings
    </hero-bar>
    <section class="section is-main-section">
      <card-component
        class="has-table has-mobile-sort-spaced"
        title="Settings"
        icon="cog"
      >
        <SettingsTable
          :data-url="`settings`"
          :checkable="false"
        />
      </card-component>
    </section>
  </div>
</template>

<script>
// import Notification from '@/components/Notification'
import SettingsTable from '@/components/SettingsTable'
import CardComponent from '@/components/CardComponent'
import HeroBar from '@/components/HeroBar'
export default {
  name: 'Tables',
  components: {
    HeroBar,
    CardComponent,
    SettingsTable
    // Notification
  },
  computed: {
  }
}
</script>
