<template>
  <div>
    <hero-bar>
      Materials
    </hero-bar>
    <section class="section is-main-section">
      <card-component
        class="has-table has-mobile-sort-spaced"
        title="Materials"
        icon="account-multiple"
      >
        <MaterialsTable
          :data-url="`materials`"
          :checkable="false"
        />
      </card-component>
    </section>
  </div>
</template>

<script>
// import Notification from '@/components/Notification'
import MaterialsTable from '@/components/MaterialsTable'
import CardComponent from '@/components/CardComponent'
import HeroBar from '@/components/HeroBar'
export default {
  name: 'Materials',
  components: {
    HeroBar,
    CardComponent,
    MaterialsTable
    // Notification
  },
  computed: {
  }
}
</script>
