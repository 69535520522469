<template>
  <div>
    <div class="locale-changer mt-5">
      <div @click="selectLanguage('en')">
        <b-image
          :src="require('@/assets/uk.png')"
          alt="English"
          style="max-width: 25px; cursor: pointer"
          class="m-3"
          @click="selectLanguage('en')"
        />
      </div>
      <div @click="selectLanguage('fr')">
        <b-image
          :src="require('@/assets/france.png')"
          alt="French"
          style="max-width: 25px; cursor: pointer"
          class="m-3"
        />
      </div>
    </div>
    <div class="home-container">
      <div
        class="card mt-5"
        @click="select('ontime')"
      >
        <div class="card-image">
          <b-image
            :src="require('@/assets/back-in-time.png')"
            alt="Back in time"
            class="home-img"
          />
        </div>
        <div class="card-content">
          <div class="media">
            <div class="media-content">
              <p class="title is-4 mt-3">
                {{ $t("calculator.movingbytime") }}
              </p>
            </div>
          </div>

          <div class="content">
            {{ $t("calculator.paragraph1") }}
          </div>
          <p class="has-text-success">
            {{ $t("calculator.benefit1") }}
          </p>
          <p class="has-text-success">
            {{ $t("calculator.benefit2") }}
          </p>
          <p class="has-text-success">
            {{ $t("calculator.benefit3") }}
          </p>
        </div>
      </div>
      <div
        class="card mt-5"
        @click="select('oninventory')"
      >
        <div class="card-image">
          <b-image
            :src="require('@/assets/inventory.png')"
            alt="Back in time"
            class="home-img"
          />
        </div>
        <div class="card-content">
          <div class="media">
            <div class="media-content">
              <p class="title is-4 mt-3">
                {{ $t("calculator.movingoninventory") }}
              </p>
            </div>
          </div>

          <div class="content">
            {{ $t("calculator.paragraph2") }}
          </div>
          <p class="has-text-success">
            {{ $t("calculator.benefit4") }}
          </p>
          <p class="has-text-success">
            {{ $t("calculator.benefit5") }}
          </p>
          <p class="has-text-success">
            {{ $t("calculator.benefit6") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      langs: ['en', 'fr']
    }
  },
  created () {
    // this.select('oninventory')
  },
  methods: {
    select (param) {
      if (param === 'ontime') {
        this.$router.push('/ontime')
      }
      if (param === 'oninventory') {
        this.$router.push('/oninventory')
      }
    },
    selectLanguage (type) {
      if (type === 'en') this.$i18n.locale = 'en'
      if (type === 'fr') this.$i18n.locale = 'fr'
    }
  }
}
</script>

<style>
.home-container {
  display: flex;
  justify-content: center;
  height: 100vh;
  margin: 0 auto;
  flex-wrap: wrap;
}
.home-container .card {
  padding: 10px;
  margin: 10px;
  max-height: 450px;
  width: 400px;
  cursor: pointer;
}
.home-img {
  max-width: 150px;
  margin: 0 auto;
}
.card-content {
  padding: 0.5rem !important;
}
.locale-changer {
  display: flex;
  justify-content: center;
}
</style>
