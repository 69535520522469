import Vue from 'vue'
import VueRouter from 'vue-router'
import Admin from '../views/Admin.vue'
import Calculator from '../views/Calculator.vue'
import OnInventory from '@/views/OnInventory'
import OnTime from '@/views/OnTime'
import InventoryItems from '@/views/InventoryItems'
import InventoryItemAdd from '@/components/InventoryItemAdd'
import SettingsAdd from '@/components/SettingsAdd'
import Settings from '@/views/Settings'
import MaterialsAdd from '@/components/MaterialsAdd'
import Materials from '@/views/Materials'
import Orders from '@/views/Orders'
import ThankYou from '@/views/ThankYou'
import Login from '@/views/Login'

import auth from '../middleware/auth';
import log from '../middleware/log';

Vue.use(VueRouter)

const routes = [
  {
    meta: {
      title: 'Home Calculator'
    },
    publicPath: '/',
    path: '/',
    name: 'Calculator',
    component: Calculator
  },
  {
    meta: {
      title: 'On Time'
    },
    publicPath: '/',
    path: '/ontime',
    name: 'OnTime',
    component: OnTime
  },
  {
    meta: {
      title: 'On Inventory'
    },
    publicPath: '/',
    path: '/oninventory',
    name: 'OnInventory',
    component: OnInventory
  },
  {
    meta: {
      title: 'Login'
    },
    publicPath: '/',
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    meta: {
      title: 'Dashboard'
    },
    publicPath: '/',
    path: '/admin',
    name: 'admin',
    component: Admin,
    meta: {
      middleware: auth,
    },
  },
  {
    meta: {
      title: 'Tables'
    },
    path: '/tables',
    name: 'tables',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "tables" */ '../views/Tables.vue'),
    meta: {
      middleware: auth,
    },
  },
  {
    meta: {
      title: 'Forms'
    },
    path: '/forms',
    name: 'forms',
    component: () => import(/* webpackChunkName: "forms" */ '../views/Forms.vue')
  },
  {
    meta: {
      title: 'Profile'
    },
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
    meta: {
      middleware: auth,
    },
  },
  {
    meta: {
      title: 'New Client'
    },
    path: '/client/new',
    name: 'client.new',
    component: () => import(/* webpackChunkName: "client-form" */ '../views/ClientForm.vue'),
    meta: {
      middleware: auth,
    },
  },
  {
    meta: {
      title: 'Edit Client'
    },
    path: '/client/:id',
    name: 'client.edit',
    component: () => import(/* webpackChunkName: "client-form" */ '../views/ClientForm.vue'),
    meta: {
      middleware: auth,
    },
    props: true
  },
  {
    path: '/thankyou',
    component: ThankYou,
    name: 'ThankYou'
  },
  {
    path: '/orders',
    component: Orders,
    meta: {
      middleware: auth,
    },
  },
  {
    meta: {
      title: 'Order View'
    },
    path: '/orders/:id',
    name: 'orders.view',
    component: () => import(/* webpackChunkName: "client-form" */ '@/components/OrderView.vue'),
    props: true,
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/materials',
    component: Materials,
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/materials/add',
    component: MaterialsAdd,
    meta: {
      middleware: auth,
    },
  },
  {
    meta: {
      title: 'Edit Material'
    },
    path: '/materials/:id',
    name: 'materials.edit',
    component: () => import(/* webpackChunkName: "client-form" */ '@/components/MaterialsEdit.vue'),
    props: true,
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/settings',
    component: Settings,
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/settings/add',
    component: SettingsAdd,
    meta: {
      middleware: auth,
    },
  },
  {
    meta: {
      title: 'Edit Setting'
    },
    path: '/settings/:id',
    name: 'settings.edit',
    component: () => import(/* webpackChunkName: "client-form" */ '@/components/SettingsEdit.vue'),
    props: true,
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/inventoryitems',
    component: InventoryItems,
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/inventoryitems/add',
    component: InventoryItemAdd,
    meta: {
      middleware: auth,
    },
  },
  {
    meta: {
      title: 'Edit Inventory Item'
    },
    path: '/inventoryitems/:id',
    name: 'inventoryitems.edit',
    component: () => import(/* webpackChunkName: "client-form" */ '@/components/InventoryItemEdit.vue'),
    props: true,
    meta: {
      middleware: auth,
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router
