<template>
  <div>
    <header-app></header-app>
    <card-component class="box-thankyou">
      <div class="w-500">
        <p>
          {{ $t("general.thankyou1") }}
        </p>
        <p class="has-text-weight-bold mt-2">
          {{ $t("general.thankyou2") }}
        </p>
      </div>
    </card-component>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import CardComponent from "../components/CardComponent.vue";
import HeaderApp from "../components/HeaderApp.vue";
export default {
  components: { HeaderApp, CardComponent },
  created() {
    this.resetState();
  },
  methods: {
    ...mapActions("inventory", ["resetState"]),
  },
};
</script>

<style >
.w-500 {
  max-width: 500px;
}
.box-thankyou {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
}
.thankyou h2 {
  font-weight: bold;
  font-size: 25px;
}
</style>
