<template>
  <div>
    <h2
      class="has-text-weight-bold has-text-centered is-size-2 is-size-4-mobile is-uppercase"
    >
      {{ $t("oninventory.title4") }}
    </h2>
    <p class="has-text-centered mb-5">
      {{ $t("oninventory.pragraph5") }}
    </p>
    <card-component
      class="has-table has-mobile-sort-spaced px-1 py-5 mx-2 my-2"
    >
      <!-- <div class="materials-container">
          <div v-for="(item, index) in materials" :key="item.id" >
          <MaterialItemPack :element="item" v-if="index <= 2" />
          </div>
      </div>
      <div class="materials-container">
          <div v-for="(item, index) in materials" :key="item.id" >
          <MaterialItem :element="item" v-if="index > 2" />
          </div>
      </div> -->
      <div class="materials-container">
        <div
          v-for="item in materials"
          :key="item.id"
        >
          <MaterialItem :element="item" />
        </div>
      </div>
    </card-component>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import axios from '@/axios'
import MaterialItem from './MaterialItem'
// import MaterialItemPack from './MaterialItemPack'
import CardComponent from '@/components/CardComponent'
export default {
  name: 'Material',
  components: {
    MaterialItem,
    // MaterialItemPack,
    CardComponent
  },
  data () {
    return {
      materials: []
    }
  },
  created () {
    axios
      .get('materials')
      .then((result) => {
        this.materials = [...result.data]
        for (const element of this.materials) {
          element.quantity = 0
        }
        this.setMaterialsToState()
      })
      .catch((e) => {
        this.isLoading = false
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger'
        })
      })
  },
  methods: {
    ...mapActions('inventory', ['setMaterials']),
    setMaterialsToState () {
      this.setMaterials(this.materials)
    }
  }
}
</script>
<style scoped>
.materials-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto;
}
</style>
