<template>
  <div>
    <modal-box
      :is-active="isModalActive"
      :trash-object-name="trashObjectName"
      @confirm="trashConfirm"
      @cancel="trashCancel"
    />
    <b-table
      :checked-rows.sync="checkedRows"
      :checkable="checkable"
      :loading="isLoading"
      :paginated="paginated"
      :per-page="perPage"
      :striped="true"
      :hoverable="true"
      default-sort="name"
      :data="orders"
    >
      <!-- <b-button type="is-info" @click="addMaterial()">Add</b-button> -->
      <b-table-column
        v-slot="props"
        cell-class="has-no-head-mobile is-image-cell"
      >
        <div class="image">
          <img :src="props.row.icon" />
        </div>
      </b-table-column>
      <b-table-column
        v-slot="props"
        label="First Name"
        field="client_first_name"
        sortable
      >
        {{ props.row.client_first_name }}
      </b-table-column>
      <b-table-column v-slot="props" label="Last Name" field="client_last_name">
        {{ props.row.client_last_name }}
      </b-table-column>
      <b-table-column v-slot="props" label="Email" field="client_email">
        {{ props.row.client_email }}
      </b-table-column>
      <b-table-column v-slot="props" label="Phone" field="client_phone">
        {{ props.row.client_phone }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        label="Date"
        field="created_at"
        :custom-sort="sortByDate"
      >
        {{ changeDate(props.row.created_at).getDay() }} /
        {{ changeDate(props.row.created_at).getMonth() }} /
        {{ changeDate(props.row.created_at).getFullYear() }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        label="Total"
        field="total"
        sortable
        centered
      >
        {{ props.row.total }} €
      </b-table-column>
      <b-table-column
        v-slot="props"
        custom-key="actions"
        cell-class="is-actions-cell"
      >
        <div class="buttons is-right">
          <router-link
            :to="{ name: 'orders.view', params: { id: props.row.id } }"
            class="button is-small is-primary"
          >
            <b-icon icon="account-edit" size="is-small" />
          </router-link>
          <!-- <button
            class="button is-small is-info"
            type="button"
            :disabled="props.row.invoice_name.length === 0"
          >
            <a
              style="color: white"
              target="_blank"
              :href="
                'https://greenmovers.lu/api/api/invoice/' +
                props.row.invoice_name
              "
            >
              <b-icon icon="file-document" size="is-small" />
            </a>
          </button>
          <button
            class="button is-small is-info"
            type="button"
            :disabled="props.row.invoice_name.length !== 0"
          >
            <a
              style="color: white"
              target="_blank"
              :href="
                'https://greenmovers.lu/api/api/invoice/' +
                props.row.invoice_name
              "
            >
              <b-icon icon="clipboard-file" size="is-small" />
            </a>
          </button> -->
          <button
            class="button is-small is-danger"
            type="button"
            @click.prevent="trashModal(props.row)"
          >
            <b-icon icon="trash-can" size="is-small" />
          </button>
        </div>
      </b-table-column>

      <section slot="empty" class="section">
        <div class="content has-text-grey has-text-centered">
          <template v-if="isLoading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large" />
            </p>
            <p>Fetching data...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="emoticon-sad" size="is-large" />
            </p>
            <p>Nothing's here&hellip;</p>
          </template>
        </div>
      </section>
    </b-table>
  </div>
</template>

<script>
import axios from "@/axios";
import ModalBox from "@/components/ModalBox";

export default {
  name: "OrdersTable",
  components: { ModalBox },
  props: {
    dataUrl: {
      type: String,
      default: null,
    },
    checkable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isModalActive: false,
      trashObject: null,
      orders: [],
      isLoading: false,
      paginated: false,
      perPage: 10,
      checkedRows: [],
    };
  },
  computed: {
    trashObjectName() {
      if (this.trashObject) {
        return this.trashObject.name;
      }

      return null;
    },
  },
  mounted() {
    this.getItems();
  },
  methods: {
    changeDate(date) {
      date = date.split("-").join("/");
      let dateOut = new Date(date);
      return dateOut;
    },
    trashModal(trashObject) {
      this.trashObject = trashObject;
      this.isModalActive = true;
    },
    trashConfirm() {
      axios
        .delete(`orders/${this.trashObject.id}`)
        .then(() => {
          this.getItems();
          this.isModalActive = false;
          this.$buefy.snackbar.open({
            message: "Confirmed",
            queue: false,
          });
        })
        .catch((e) => {
          this.isLoading = false;
          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: "is-danger",
          });
        });
    },
    trashCancel() {
      this.isModalActive = false;
    },
    addMaterial() {
      this.$router.push("orders/add");
    },
    getItems() {
      if (this.dataUrl) {
        this.isLoading = true;
        axios
          .get(this.dataUrl)
          .then((result) => {
            this.isLoading = false;
            if (result.data) {
              if (result.data.length > this.perPage) {
                this.paginated = true;
              }
              this.orders = [...result.data];
            }
          })
          .catch((e) => {
            this.isLoading = false;
            this.$buefy.toast.open({
              message: `Error: ${e.message}`,
              type: "is-danger",
            });
          });
      }
    },
    generateInvoice(invoiceName) {
      axios
        .get(`invoice/${invoiceName}`, {})
        .then((result) => {
          console.log(result.data);
        })
        .catch((e) => {
          this.isLoading = false;
          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: "is-danger",
          });
        });
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return (
          new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
        );
      } else {
        return (
          new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
        );
      }
    },
  },
};
</script>

<style scoped>
.image {
  max-width: 50px;
}
</style>
