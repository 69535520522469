<template>
  <div id="app">
    <div v-if="currentRouteName === 'OnInventory'">
      <OnInventory />
    </div>
    <div v-if="currentRouteName === 'OnTime'">
      <OnTime />
    </div>
    <div v-if="currentRouteName === 'Calculator'">
      <Calculator />
    </div>
    <div v-if="currentRouteName === 'ThankYou'">
      <ThankYou />
    </div>
    <div v-if="currentRouteName === 'login'">
      <Login />
    </div>
    <div
      v-if="
        currentRouteName !== 'OnInventory' &&
        currentRouteName !== 'OnTime' &&
        currentRouteName !== null &&
        currentRouteName !== 'ThankYou' &&
        currentRouteName !== 'login' &&
        currentRouteName !== 'Calculator'
      "
      class="has-aside-left p-14rem has-navbar-fixed-top"
    >
      <nav-bar />
      <aside-menu :menu="menu" />
      <router-view />
      <footer-bar />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from "@/components/NavBar";
import AsideMenu from "@/components/AsideMenu";
import FooterBar from "@/components/FooterBar";
import OnInventory from "@/views/OnInventory";
import OnTime from "@/views/OnTime";
import Calculator from "@/views/Calculator";
import ThankYou from "@/views/ThankYou";
import Login from "@/views/Login";
export default {
  name: "Home",
  components: {
    NavBar,
    FooterBar,
    AsideMenu,
    OnInventory,
    OnTime,
    Calculator,
    ThankYou,
    Login,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    menu() {
      return [
        "General",
        [
          {
            to: "/admin",
            icon: "desktop-mac",
            label: "Dashboard",
          },
        ],
        "Tables",
        [
          {
            to: "/orders",
            label: "Orders",
            icon: "table",
            updateMark: false,
          },
          {
            to: "/materials",
            label: "Materials",
            icon: "square-edit-outline",
          },
          {
            to: "/inventoryitems",
            label: "Inventory",
            icon: "account-circle",
          },
          {
            to: "/settings",
            label: "Settings",
            icon: "account-circle",
          },
          {
            label: "Submenus",
            subLabel: "Submenus Example",
            icon: "view-list",
            menu: [
              {
                href: "#void",
                label: "Sub-item One",
              },
              {
                href: "#void",
                label: "Sub-item Two",
              },
            ],
          },
        ],
      ];
    },
  },
  created() {
    console.log(this.$route.name);
    this.$store.commit("user", {
      name: "Green Movers",
      email: "contact@greenmovers.lu",
      avatar: "https://image.flaticon.com/icons/png/512/236/236832.png",
    });
  },
};
</script>

<style>
@media screen and (min-width: 1023px) {
  .p-14rem {
    padding-left: 14rem;
  }
}

a:hover {
  color: white !important;
}
</style>
