<template>
  <div>
    <h2
      class="
        has-text-centered has-text-weight-bold
        is-size-2 is-size-4-mobile is-uppercase
      "
    >
      {{ $t("general.contact") }}
    </h2>
    <card-component
      class="
        has-table has-text-centered has-mobile-sort-spaced
        px-3
        py-4
        m-3
        has-text-centered
      "
    >
      <div class="payment-container">
        <b-field
          grouped
          expanded
        >
          <b-field
            :label="$t('general.emailp')"
            expanded
          >
            <b-input
              v-model="contact.email"
              type="email"
              @blur="setContactToState()"
            />
          </b-field>
        </b-field>

        <b-field grouped>
          <b-field
            :label="$t('general.lastname')"
            expanded
          >
            <b-input
              v-model="contact.last_name"
              @blur="setContactToState()"
            />
          </b-field>
          <b-field
            :label="$t('general.firstname')"
            expanded
          >
            <b-input
              v-model="contact.first_name"
              @blur="setContactToState()"
            />
          </b-field>
        </b-field>

        <b-field grouped>
          <b-field
            :label="$t('general.phone')"
            expanded
          >
            <b-input
              v-model="contact.phone"
              @blur="setContactToState()"
            />
          </b-field>
          <b-field
            :label="$t('general.promotion')"
            expanded
          >
            <b-select
              v-model="promotion"
              :placeholder="$t('general.select')"
              expanded
            >
              <option>Facebook</option>
              <option>Instagram</option>
              <option>Google</option>
              <option>Friend</option>
              <option>{{ $t("general.other") }}</option>
            </b-select>
          </b-field>
        </b-field>
        <b-field>
          <b-checkbox
            v-model="accept"
            true-value="Yes"
            false-value="No"
          >
            {{ $t("general.doyouaccept") }}
            <a href="https://greenmovers.lu/terms-conditions/">{{
              $t("general.termsnconditions")
            }}</a>
            &
            <a href="https://greenmovers.lu/privacy-policy/">{{
              $t("general.privacypolicy")
            }}</a>
            ?
          </b-checkbox>
        </b-field>
        <div>
          <b-button
            type="is-success"
            @click="submit()"
          >
            {{ $t('oninventory.sendOrder') }}
          </b-button>
        </div>
        <!-- <div style="max-width: 500px; margin: 0 auto">
          <div class="stripe my-4">
            <svg
              viewBox="0 0 60 25"
              xmlns="http://www.w3.org/2000/svg"
              width="60"
              height="25"
              class="UserLogo variant--"
            >
              <title>Stripe logo</title>
              <path
                fill="var(--userLogoColor, #0A2540)"
                d="M59.64 14.28h-8.06c.19 1.93 1.6 2.55 3.2 2.55 1.64 0 2.96-.37 4.05-.95v3.32a8.33 8.33 0 0 1-4.56 1.1c-4.01 0-6.83-2.5-6.83-7.48 0-4.19 2.39-7.52 6.3-7.52 3.92 0 5.96 3.28 5.96 7.5 0 .4-.04 1.26-.06 1.48zm-5.92-5.62c-1.03 0-2.17.73-2.17 2.58h4.25c0-1.85-1.07-2.58-2.08-2.58zM40.95 20.3c-1.44 0-2.32-.6-2.9-1.04l-.02 4.63-4.12.87V5.57h3.76l.08 1.02a4.7 4.7 0 0 1 3.23-1.29c2.9 0 5.62 2.6 5.62 7.4 0 5.23-2.7 7.6-5.65 7.6zM40 8.95c-.95 0-1.54.34-1.97.81l.02 6.12c.4.44.98.78 1.95.78 1.52 0 2.54-1.65 2.54-3.87 0-2.15-1.04-3.84-2.54-3.84zM28.24 5.57h4.13v14.44h-4.13V5.57zm0-4.7L32.37 0v3.36l-4.13.88V.88zm-4.32 9.35v9.79H19.8V5.57h3.7l.12 1.22c1-1.77 3.07-1.41 3.62-1.22v3.79c-.52-.17-2.29-.43-3.32.86zm-8.55 4.72c0 2.43 2.6 1.68 3.12 1.46v3.36c-.55.3-1.54.54-2.89.54a4.15 4.15 0 0 1-4.27-4.24l.01-13.17 4.02-.86v3.54h3.14V9.1h-3.13v5.85zm-4.91.7c0 2.97-2.31 4.66-5.73 4.66a11.2 11.2 0 0 1-4.46-.93v-3.93c1.38.75 3.1 1.31 4.46 1.31.92 0 1.53-.24 1.53-1C6.26 13.77 0 14.51 0 9.95 0 7.04 2.28 5.3 5.62 5.3c1.36 0 2.72.2 4.09.75v3.88a9.23 9.23 0 0 0-4.1-1.06c-.86 0-1.44.25-1.44.9 0 1.85 6.29.97 6.29 5.88z"
                fill-rule="evenodd"
              />
            </svg>
          </div>
          <stripe-element-card
            ref="elementRef"
            :pk="publishableKey"
            @token="tokenCreated"
          />

          <b-button type="is-success" @click="$refs.elementRef.submit()">
            {{ $t("general.paynow") }}
          </b-button>
        </div> -->
      </div>
    </card-component>
  </div>
</template>

<script>
import axios from '@/axios'

import { mapGetters, mapState, mapActions } from 'vuex'
import CardComponent from '@/components/CardComponent'
export default {
  name: 'Payment',
  components: {
    CardComponent
  },
  data () {
    return {
      isFullPage: true,
      complete: false,
      accept: false,
      promotion: '',
      errorMessage: '',
      stripeOptions: {
        hidePostalCode: true
      },
      loading: false,
    }
  },
  computed: {
    ...mapState({
      inventory: (state) => state.inventory
    }),
    ...mapGetters('inventory', {
      total: 'getOrderTotal',
      contact: 'getContact',
      settings: 'getSettings',
      totalInclTax: 'getOrderTotalInclTax'
    })
  },
  methods: {
    errorHandler (event) {
      this.errorMessage = event.error ? event.error.message : ''
    },
    tokenCreated (token) {
      this.token = token
      if (
        this.contact.email === '' ||
        this.contact.first_name === '' ||
        this.contact.last_name === '' ||
        this.contact.phone === ''
      ) {
        this.$buefy.snackbar.open({
          duration: 5000,
          message: 'All fields are required!',
          type: 'is-danger',
          position: 'is-bottom',
          queue: false
        })
        return
      }
      if (this.total < parseFloat(this.settings.minimumOrder)) {
        let minimum = parseFloat(this.settings.minimumOrder)
        minimum +=
          (parseFloat(this.settings.minimumOrder) *
            parseFloat(this.settings.VAT)) /
          100
        this.$buefy.snackbar.open({
          indefinite: true,
          message: `The order should be over <b>${this.settings.minimumOrder}€</b>. Do you accept it?`,
          cancelText: 'Cancel',
          onAction: () => {
            this.pay(minimum)
            this.isLoading = true
            setTimeout(() => {
              this.isLoading = false
            }, 10 * 1000)
          }
        })
      } else {
        this.pay(this.totalInclTax)
      }
    },
    async pay (total) {
      axios
        .post('checkout', {
          stripeToken: this.token.id,
          amount: total
        })
        .then((result) => {
          this.postOrder(total)
        })
        .catch(error => {
          console.log(error)
          this.$buefy.snackbar.open({
            duration: 5000,
            message: 'Payment failed! Try again!',
            type: 'is-danger',
            position: 'is-bottom',
            queue: false
          })
        })
    },
    ...mapActions('inventory', ['setContact']),
    setContactToState () {
      this.setContact({ contact: this.contact })
    },
    submit () {
      if (this.contact.email === '' || this.contact.first_name === '' || this.contact.last_name === '' || this.contact.phone === '') {
        this.$buefy.snackbar.open({
          duration: 5000,
          message: 'All fields are required!',
          type: 'is-danger',
          position: 'is-bottom',
          queue: false
        })
        return
      }
      this.postOrder(100)
      console.log('send order')
      this.$router.push('/thankyou')
      // You will be redirected to Stripe's secure checkout page
      // this.$refs.checkoutRef.redirectToCheckout();
    },
    sendEmail (dates, services, inventory, disassembly, materials) {
      axios.post('sendOrderEmail', {
        client_first_name: this.contact.first_name,
        client_last_name: this.contact.last_name,
        client_phone: this.contact.phone,
        client_email: this.contact.email,
        dates: JSON.stringify(dates),
        services: JSON.stringify(services),
        departure: JSON.stringify(this.inventory.departure),
        destination: JSON.stringify(this.inventory.destination),
        inventory: JSON.stringify(inventory),
        disassembly: JSON.stringify(disassembly),
        material: JSON.stringify(materials),
        total: this.totalInclTax,
        promotion: this.promotion
      })
    },
    postOrder (total) {
      const inventory = this.inventory.inventory.map((item) => {
        // delete item.icon
        return item
      })
      const disassembly = this.inventory.inventory.filter((item) => {
        // delete item.icon
        return item.disassembly_quantity > 0
      })
      const materials = this.inventory.materials.map((item) => {
        // delete item.image
        return item
      })
      const dates = {
        moveDate: this.inventory.moveDate,
        deliveryDate: this.inventory.deliveryDate,
        pickupDate: this.inventory.pickupDate
      }
      console.log(dates)
      const services = {
        cleaningHours: this.inventory.cleaningHours,
        packingUnpackingHours: this.inventory.packingUnpackingHours,
        warranty: this.inventory.warranty,
        cancellation: this.inventory.cancellation,
        promotion: this.promotion,
        truck: this.inventory.truck,
        truckNumber: this.inventory.truckNumber,
        workers: this.inventory.workers,
        hours: this.inventory.hours,
        parkingPermit: this.inventory.parkingPermit,
        extElevator: this.inventory.extElevator,
        serviceTitle: this.inventory.activeStep.title
      }
      this.sendEmail(dates, services, inventory, disassembly, materials)
      axios
        .post('orders', {
          client_first_name: this.contact.first_name,
          client_last_name: this.contact.last_name,
          client_phone: this.contact.phone,
          client_email: this.contact.email,
          total: this.totalInclTax,
          dates: JSON.stringify(dates),
          services: JSON.stringify(services),
          departure: JSON.stringify(this.inventory.departure),
          destination: JSON.stringify(this.inventory.destination),
          inventory: JSON.stringify(inventory),
          disassembly: JSON.stringify(disassembly),
          material: JSON.stringify(materials)
        })
        .then((result) => {
          this.$router.push('/thankyou')
        })
    }
  }
}
</script>
<style scoped>
.stripe {
  display: flex;
  align-content: center;
}

#card-errors {
  color: #fa755a;
}
.StripeElement {
  box-sizing: border-box;
  height: 45px;
  padding: 10px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
}

.StripeElement--invalid {
  border-color: #fa755a;
}
.StripeElement--webkit-autofill {
  background-color: #fa755a !important;
}

.payment-container {
  max-width: 600px;
  text-align: center;
  margin: 0 auto;
}

@media only screen and (max-width: 768px) {
  .payment-container .field.is-grouped {
    flex-direction: column !important;
  }
}
</style>
