<template>
  <div>
    <h2
      class="has-text-centered has-text-weight-bold is-size-2 is-size-4-mobile is-uppercase mb-6"
    >
      {{ $t("oninventory.title6") }}
    </h2>
    <card-component class="has-table has-mobile-sort-spaced px-6 py-4 m-3">
      <div class="box-container">
        <b-image
          :src="require('@/assets/household.png')"
          alt="cleaning service"
          class="service-icon"
        />
        <b-field :label="$t('oninventory.paragraph32')">
          <b-numberinput
            v-model="cleaningHours"
            :controls="false"
            @input="changeCleaningHours()"
          />
        </b-field>
        <p class="ml-5 has-text-weight-bold">
          {{ settings.cleaningPerHourCost }}€/h
        </p>
      </div>
    </card-component>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CardComponent from "@/components/CardComponent";
export default {
  name: "CleaningService",
  components: {
    CardComponent,
  },
  data() {
    return {
      cleaningHours: 0,
    };
  },
  computed: {
    ...mapGetters("inventory", {
      settings: "getSettings",
    }),
  },
  methods: {
    ...mapActions("inventory", ["setCleaningHours"]),
    changeCleaningHours() {
      this.setCleaningHours(this.cleaningHours);
    },
  },
};
</script>

<style scoped>
.box-4 {
  margin: 20px;
  padding: 20px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.box-container {
  max-width: 600px;
  display: flex;
  text-align: center;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}
</style>
