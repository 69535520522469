<template>
  <div class="container">
    <h2
      class="has-text-weight-bold has-text-centered is-size-3 is-size-4-mobile is-uppercase"
    >
      {{ $t("oninventory.title1") }} <br>
    </h2>
    <p class="has-text-centered">
      {{ $t("oninventory.paragraph1") }}
    </p>
    <div style="max-width: 900px; margin: 20px auto">
      <!-- <b-button
      outlined
        type="is-primary"
        class="btn-search"
        >
        <span style="margin-right: 20px">{{dropdownText}}</span>
          <v-icon name="search" />
    </b-button> -->
      <b-field>
        <b-input
          v-model="searchValue"
          type="search"
          icon="magnify"
          icon-clickable
        />
      </b-field>
    </div>
    <div class="inventory-container p-2">
      <div
        v-for="item in searchResult"
        :key="item.id"
        @click="addItemToInventory(item)"
      >
        <InventoryItem :element="item" />
      </div>
    </div>
    <h2
      v-if="inventory != undefined && inventory.length !== 0"
      class="has-text-weight-bold has-text-centered mt-5"
    >
      {{ $t("general.inventory") }}
    </h2>
    <card-component
      v-if="inventory != undefined && inventory.length !== 0"
      class="has-table has-mobile-sort-spaced px-6 py-4 m-3"
    >
      <div
        v-for="item in inventory"
        :key="item.id"
      >
        <InventoryItemAdded :element="item" />
      </div>
    </card-component>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import InventoryItem from './InventoryItem'
import InventoryItemAdded from './InventoryItemAdded'
import CardComponent from '@/components/CardComponent'
import axios from '@/axios'
export default {
  name: 'Inventory',
  components: {
    InventoryItem,
    InventoryItemAdded,
    CardComponent
  },
  data () {
    return {
      isScrollable: true,
      maxHeight: 300,
      inventoryItems: [],
      searchValue: ''
    }
  },
  computed: {
    ...mapState({
      inventory: (state) => state.inventory
    }),
    ...mapGetters('inventory', {
      inventory: 'getInventory',
      total: 'getInventoryTotal',
      materials: 'getMaterialsTotal',
      volume: 'getVolumeTotal',
      starterPrice: 'getStarterPrice'
    }),
    searchResult () {
      let searchedInventory = this.inventoryItems

      if (this.searchValue !== '' && this.searchValue) {
        searchedInventory = searchedInventory.filter((item) => {
          return item.name
            .toUpperCase()
            .includes(this.searchValue.toUpperCase())
        })
      }
      return searchedInventory
    }
  },
  created () {
    this.getInventoryItems()
  },
  methods: {
    checkout (products) {
      this.$store.dispatch('cart/checkout', products)
    },
    sortList (list) {
      const orderdList = list.sort(function (a, b) {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })

      return orderdList
    },
    ...mapActions('inventory', ['addItemToInventory']),
    getInventoryItems () {
      axios
        .get('inventoryitems')
        .then((result) => {
          const items = this.sortList(result.data)
          this.inventoryItems = items
        })
        .catch((e) => {
          this.isLoading = false
          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: 'is-danger'
          })
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn-search {
  width: 100%;
}
.dropdown-inventory {
  margin: 30px 0;
}
.inventory-container {
  max-height: 300px;
  max-width: 900px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0px 0 1px rgb(10 10 10 / 2%);
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  margin: 20px auto;
}
</style>
