<template>
  <div>
    <HeaderApp />
    <div class="columns mt-5">
      <div class="column main-side">
        <b-steps v-model="activeStep">
          <b-step-item
            step="1"
            label=""
          >
            <Inventory />
          </b-step-item>

          <b-step-item
            step="2"
            label=""
          >
            <Schedule />
          </b-step-item>

          <b-step-item
            step="3"
            label=""
          >
            <Dissambly />
          </b-step-item>

          <b-step-item
            step="4"
            label=""
          >
            <Material />
          </b-step-item>

          <b-step-item
            step="5"
            label=""
          >
            <DeliveryAndPickup />
          </b-step-item>

          <!-- <b-step-item step="6" label="">
            <CleaningService />
          </b-step-item> -->

          <b-step-item
            step="6"
            label=""
          >
            <PackingAndUnpacking />
          </b-step-item>

          <b-step-item
            step="7"
            label=""
          >
            <ExtElevator />
            <LiftAuthorisation />
          </b-step-item>

          <!-- <b-step-item
            step="7"
            label=""
          >
          </b-step-item> -->

          <b-step-item
            step="8"
            label=""
          >
            <ParkingPermit />
          </b-step-item>

          <b-step-item
            step="9"
            label=""
          >
            <Warranty />
          </b-step-item>

          <b-step-item
            step="10"
            label=""
          >
            <Cancellation />
          </b-step-item>

          <b-step-item
            step="11"
            label=""
          >
            <Address />
          </b-step-item>

          <b-step-item
            step="12"
            label=""
          >
            <Recapitulatory />
          </b-step-item>

          <b-step-item
            step="13"
            label=""
            disabled
          >
            <Payment />
          </b-step-item>
          <template #navigation="{ previous, next }">
            <div class="btn-prevnext">
              <b-button
                type="is-danger"
                :disabled="previous.disabled"
                @click.prevent="previous.action"
              >
                {{ $t("general.previous") }}
              </b-button>
              <b-button
                type="is-primary"
                :disabled="next.disabled"
                @click.prevent="next.action"
              >
                {{ $t("general.next") }}
              </b-button>
            </div>
          </template>
        </b-steps>
      </div>
      <div class="column is-2 mx-2 sidebar">
        <div class="has-text-right pr-2 pt-5 price">
          <p class="is-size-5">
            {{ $t("general.volume") }}:
            <strong> {{ volume.toFixed(2) }} m<sup>3</sup> </strong>
          </p>
          <p class="is-size-5">
            <strong
              v-if="activeStep >= 10"
              class="is-size-4 has-text-weight-bold has-text-success"
            >
              {{ $t("general.total") }} : {{ totalInclTax.toFixed(2) }} €
              </br>
              <span class="is-size-5 has-text-weight-normal">
                {{ $t("general.vat") }}: {{ (totalInclTax - total).toFixed(2) }} €
              </span>
            </strong>
            <strong
              v-else
              class="is-size-4 has-text-weight-bold has-text-success"
            >
              {{ $t("general.total") }}: {{ total.toFixed(2) }} €
            </strong>
          </p>
          <p
            v-if="!(total > parseFloat(settings.minimumOrder))"
            class="is-size-5"
          >
            {{ $t("general.minimum") }}:
            <strong class="is-size-5 has-text-weight-bold has-text-danger">
              {{ settings.minimumOrder }} €
            </strong>
          </p>
          <p
            v-if="dissamblyItems.length !== 0"
            class="mt-5"
          >
            <strong>{{ $t("general.assembling") }}</strong>
          </p>
          <p
            v-for="item in dissamblyItems"
            :key="item.id"
          >
            <span
              v-if="item.disassembly_quantity > 0"
            >{{ $i18n.locale === "en" ? item.name : item.name_fr }} -
              {{ item.disassembly_quantity }}</span>
          </p>
          <p class="mt-5">
            <strong>{{ $t("general.inventory") }}</strong>
          </p>
          <p
            v-for="item in inventory"
            :key="'A' + item.id"
          >
            {{ $i18n.locale === "en" ? item.name : item.name_fr }} -
            {{ item.quantity }}
          </p>
          <p
            v-for="item in materials"
            :key="'B' + item.id"
          >
            <span
              v-if="item.quantity > 0"
            >{{ $i18n.locale === "en" ? item.name : item.name_fr }} -
              {{ item.quantity }}</span>
          </p>
          <p
            v-if="cleaningHours > 0"
            class="has-text-weight-bold"
          >
            {{ $t("general.cleaning") }} - {{ cleaningHours }}
            {{ $t("general.hours") }}
          </p>
          <p
            v-if="packingUnpackingHours > 0"
            class="has-text-weight-bold"
          >
            {{ $t("general.packing") }} - {{ packingUnpackingHours }}
            {{ $t("general.hours") }}
          </p>
          <p
            v-if="elevatorHours.departure > 0"
            class="has-text-weight-bold"
          >
            {{ $t("oninventory.paragraph33") }} - {{ elevatorHours.departure }}
            {{ $t("general.hours") }}
          </p>
          <p
            v-if="elevatorHours.destination > 0"
            class="has-text-weight-bold"
          >
            {{ $t("oninventory.paragraph34") }} - {{ elevatorHours.destination }}
            {{ $t("general.hours") }}
          </p>
          <p
            v-if="parkingStatus.departure && activeStep >= 6"
            class="has-text-weight-bold"
          >
            {{ $t("oninventory.paragraph35") }}
          </p>
          <p
            v-if="parkingStatus.destination && activeStep >= 6"
            class="has-text-weight-bold"
          >
            {{ $t("oninventory.paragraph36") }}
          </p>
          <p
            v-if="liftAuthorisation.departure && activeStep >= 6"
            class="has-text-weight-bold"
          >
            {{ $t("oninventory.paragraph37") }}
          </p>
          <p
            v-if="liftAuthorisation.destination && activeStep >= 6"
            class="has-text-weight-bold"
          >
            {{ $t("oninventory.paragraph38") }}
          </p>

          <p
            v-if="activeStep >= 8"
            class="has-text-weight-bold"
          >
            {{ $t("oninventory.title11") }}
          </p>
          <p
            v-if="warranty && activeStep >= 8"
            class="has-text-weight-bold"
          >
            {{ $t("oninventory.title12") }}
          </p>
          <p
            v-if="activeStep >= 9"
            class="has-text-weight-bold"
          >
            {{ $t("oninventory.paragraph15") }}
          </p>
          <p
            v-if="cancellation.status && activeStep >= 9"
            class="has-text-weight-bold"
          >
            {{ $t("oninventory.paragraph16") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ParkingPermit from '../components/ParkingPermit.vue'
import LiftAuthorisation from '../components/LiftAuthorisation.vue'
import Inventory from '../components/Inventory.vue'
import Schedule from '../components/Schedule.vue'
import Dissambly from '../components/Dissambly.vue'
import Material from '../components/Material.vue'
import Warranty from '../components/Warranty.vue'
import Cancellation from '../components/Cancellation.vue'
import Address from '../components/Address.vue'
import Recapitulatory from '../components/Recapitulatory.vue'
import Payment from '../components/Payment.vue'
import DeliveryAndPickup from '../components/DeliveryAndPickup.vue'
import CleaningService from '../components/CleaningService.vue'
import PackingAndUnpacking from '../components/PackingAndUnpacking.vue'
import ExtElevator from '../components/ExtElevator.vue'
import HeaderApp from '../components/HeaderApp.vue'
import axios from '@/axios'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    ParkingPermit,
    LiftAuthorisation,
    Inventory,
    Schedule,
    Dissambly,
    Material,
    Warranty,
    Cancellation,
    Address,
    Recapitulatory,
    Payment,
    DeliveryAndPickup,
    CleaningService,
    PackingAndUnpacking,
    ExtElevator,
    HeaderApp
  },
  data () {
    return {
      activeStep: 0,
      customNavigation: true,
      settings: {}
    }
  },
  computed: {
    ...mapGetters('inventory', {
      total: 'getOrderTotal',
      starterPrice: 'getStarterPrice',
      volume: 'getVolumeTotal',
      inventory: 'getInventory',
      materials: 'getMaterials',
      cleaningHours: 'getCleaningHours',
      packingUnpackingHours: 'getPackingUnpackingHours',
      totalInclTax: 'getOrderTotalInclTax',
      elevatorHours: 'getElevatorHours',
      parkingStatus: 'getParkingStatus',
      liftAuthorisation: 'getLiftAuthorisation',
      warranty: 'getWarranty',
      cancellation: 'getCancellation'
    }),
    dissamblyItems () {
      return this.inventory.filter((item) => item.disassembly_quantity !== 0)
    }
  },
  watch: {
    activeStep (val) {
      this.updateActiveStep({
        step: val,
        title: this.$route.name
      })
    }
  },
  created () {
    this.resetState()
    axios.get('settings').then((result) => {
      const object = result.data.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: item.value }),
        {}
      )
      this.settings = { ...object }
      // this.settings = [...result.data]
      this.setSettingsToState()
    })
  },
  methods: {
    ...mapActions('inventory', [
      'setSettings',
      'setWarrantyPrice',
      'setCancellationPrice',
      'resetState',
      'updateActiveStep'
    ]),
    setSettingsToState () {
      this.setSettings(this.settings)
    }
  }
}
</script>

<style lang="scss">
.service-icon {
  max-width: 80px;
  margin-right: 30px;
}

.btn-prevnext {
  margin: 0 auto;
  text-align: center;
  button {
    margin: 10px;
    min-width: 150px;
  }
}

.sidebar {
  height: 100vh;
  border-left: 3px solid #1f335252;
  position: fixed;
  position: -webkit-fixed;
  right: 0;
  margin-top: -145px;
  background-color: #f3f3f3;
  z-index: -1;
  margin-right: 0 !important;
}

.main-side {
  margin-right: 16.6666666667%;
}

.header-container {
  background-color: #23395b;
  padding: 40px;
  padding-bottom: 0;
  display: flex;
  justify-content: center;
}

.icons,
.email-section {
  margin: 0px 10px 0px 0px;
  padding: 0px 10px 0px 0px;
  border-style: solid;
  border-width: 0px 1px 0px 0px;
  border-color: rgba(255, 255, 255, 0.1);
}

.fb-icon {
  background-color: #3b5998;
  color: #ffffff;
  font-family: Lato, sans-serif;
  font-size: 20px;
  font-weight: 400;
  height: 40px;
  line-height: 20px;
  margin: 0px;
  padding: 0px;
  width: 40px;
  border-radius: 50%;
  margin-right: 5px;
}
.insta-icon {
  background-color: #f0085a;
  color: #ffffff;
  font-family: Lato, sans-serif;
  font-size: 20px;
  font-weight: 400;
  height: 40px;
  line-height: 20px;
  margin: 0px;
  padding: 0px;
  width: 40px;
  border-radius: 50%;
  margin-right: 5px;
}

.linkedin-icon {
  background-color: #0077b5;
  color: #ffffff;
  font-family: Lato, sans-serif;
  font-size: 20px;
  font-weight: 400;
  height: 40px;
  line-height: 20px;
  margin: 0px;
  padding: 0px;
  width: 40px;
  border-radius: 50%;
  margin-right: 5px;
}

.contact-icon {
  background-color: #efc42d;
  color: #23395b;
  font-family: Lato, sans-serif;
  font-size: 20px;
  font-weight: 400;
  height: 40px;
  line-height: 20px;
  margin: 0px;
  padding: 10px;
  width: 40px;
  border-radius: 50%;
}

.contact-icon i::before {
  color: #23395b;
}

.contact-title {
  font-family: "Muli", Sans-serif;
  color: #7b879c;
  font-size: 13px;
  font-weight: 700;
  font-style: normal;
  line-height: 1em;
  letter-spacing: 0.5px;
}

.contact-details {
  color: #ffffff;
  font-family: Muli, sans-serif;
  font-size: 18px;
  font-weight: 700;
  height: 23px;
  line-height: 18px;
  margin: 0px;
  padding: 0px;
  width: 180px;
}

.price {
  margin-top: 250px;
}

@media only screen and (max-width: 600px) {
  .header-container {
    padding: 10px;
  }
  .main-side {
    margin-right: 0;
  }
  .sidebar {
    position: inherit;
  }
  .price {
    margin-top: 100px;
    margin-right: 20px;
  }
  .material-container {
    height: 380px;
  }
  .icons-header,
  .contact-icon {
    display: none;
  }
}
</style>
