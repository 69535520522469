<template>
  <div>
    <h2
      class="has-text-centered has-text-weight-bold is-size-2 is-size-4-mobile is-uppercase"
    >
      {{ $t("oninventory.title5") }}
    </h2>
    <p class="has-text-centered mb-5">
      {{ $t("oninventory.paragraph6") }}
      <strong>{{ $t("oninventory.paragraph7") }}</strong>
    </p>
    <card-component class="has-table has-mobile-sort-spaced px-6 py-4 m-3">
      <div class="calendars">
        <div class="calendar-item">
          <p>Delivery day</p>
          <b-datepicker
            v-model="delivery"
            class="has-text-centered m-2"
            :min-date="minDate"
            :timepicker="timepicker"
            inline
            @input="changeDeliveryDay()"
          />
          <b-timepicker
            v-model="time"
            class="has-text-centered mt-2"
            :min-time="min"
            :max-time="max"
            :increment-minutes="incrementMinutes"
            inline
            @input="changeDeliveryDay()"
          />
        </div>
        <div class="calendar-item">
          <p>Pick-up day</p>
          <b-datetimepicker
            v-model="pickup"
            class="has-text-centered m-2"
            :min-date="delivery"
            disabled
            inline
          />
        </div>
      </div>
    </card-component>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import CardComponent from '@/components/CardComponent'
export default {
  name: 'DeliveryAndPickup',
  components: {
    CardComponent
  },
  data () {
    const today = new Date()
    const min = new Date()
    min.setHours(9)
    min.setMinutes(0)
    const max = new Date()
    max.setHours(20)
    max.setMinutes(0)

    return {
      delivery: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 1
      ),
      minDate: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 1
      ),
      pickup: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 14
      ),
      time: min,
      min: min,
      max: max,
      incrementMinutes: 15,
      timepicker: {
        incrementMinutes: 15,
        time: min,
        minTime: min,
        maxTime: max
      }
    }
  },
  methods: {
    ...mapActions('inventory', [
      'setMoveDate',
      'setDeliveryDate',
      'setPickupDate'
    ]),
    changeDeliveryDay () {
      const datetime = new Date(
        this.delivery.getFullYear(),
        this.delivery.getMonth(),
        this.delivery.getDate(),
        this.time.getHours(),
        this.time.getMinutes(),
        this.time.getSeconds()
      )
      this.setDeliveryDate(datetime)
      this.pickup = new Date(datetime)
      this.pickup.setDate(this.pickup.getDate() + 14)
      this.setPickupDate(this.pickup)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.calendars {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.calendar-item p {
  text-align: center;
  font-weight: bold;
}
</style>
