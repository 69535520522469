<template>
  <header>
    <div class="header-container">
      <div class="icons-header">
        <b-icon class="fb-icon" icon="facebook" />
        <b-icon class="insta-icon" icon="instagram" />
        <b-icon class="linkedin-icon" icon="linkedin" />
      </div>
      <div class="email-section">
        <div class="columns">
          <div class="column is-2">
            <b-icon class="contact-icon" icon="email" />
          </div>
          <div class="column">
            <div class="contact-title">
              {{ $t("general.email") }}
            </div>
            <a class="contact-details" href="mailto:info@greenmovers.lu"
              >info@greenmovers.lu</a
            >
          </div>
        </div>
      </div>
      <div class="">
        <div class="columns">
          <div class="column is-2 mr-2">
            <b-icon class="contact-icon" icon="phone" />
          </div>
          <div class="column">
            <div class="contact-title">
              {{ $t("general.callus") }}
            </div>
            <a class="contact-details" href="tel:+352 621 356 761"
              >621 356 761</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="locale-changer py-2" style="background-color: #23395b">
      <div @click="selectLanguage('en')">
        <b-image
          :src="require('@/assets/uk.png')"
          alt="English"
          style="max-width: 25px; cursor: pointer"
          class="m-3"
          @click="selectLanguage('en')"
        />
      </div>
      <div @click="selectLanguage('fr')">
        <b-image
          :src="require('@/assets/france.png')"
          alt="French"
          style="max-width: 25px; cursor: pointer"
          class="m-3"
        />
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderApp",
  methods: {
    selectLanguage(type) {
      if (type === "en") this.$i18n.locale = "en";
      if (type === "fr") this.$i18n.locale = "fr";
    },
  },
};
</script>

<style>
</style>
