<template>
  <div class="columns is-centered is-align-items-center">
    <div class="column is-1">
      <img class="icons" :src="element.icon" />
    </div>
    <div class="column is-9">
      {{ $i18n.locale === "en" ? element.name : element.name_fr }}
    </div>
    <div class="column is-2 btn-plusminus">
      <b-button type="is-danger">
        <v-icon name="plus" />
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "InventoryItem",
  props: {
    element: Object,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn-delete {
  cursor: pointer;
  font-size: 18px;
}
.btn-delete:hover {
  font-size: 24px;
}
.btn-plusminus {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-plusminus button {
  padding: 10px;
}
.icons {
  max-width: 48px;
}
@media screen and (max-width: 768px) {
  .columns {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
