<template>
  <div>
    <h2
      class="has-text-centered has-text-weight-bold is-size-2 is-size-4-mobile is-uppercase mb-6"
    >
      {{ $t("ontime.title") }}
    </h2>
    <card-component class="has-table has-mobile-sort-spaced px-6 py-4 m-3">
      <div class="box-container">
        <div
          class="worker"
          @click="selectWorker(1)"
        >
          <b-image
            v-if="workers === 0"
            :src="require('@/assets/technician.png')"
            alt="packing unpacking"
            class="service-icon mb-3 mr-0"
          />
          <b-image
            v-if="workers > 0"
            :src="require('@/assets/technician-color.png')"
            alt="packing unpacking"
            class="service-icon mb-3 mr-0"
          />
          <p>1</p>
        </div>
        <div
          class="worker"
          @click="selectWorker(2)"
        >
          <b-image
            v-if="workers <= 1"
            :src="require('@/assets/technician.png')"
            alt="packing unpacking"
            class="service-icon mb-3 mr-0"
          />
          <b-image
            v-if="workers >= 2"
            :src="require('@/assets/technician-color.png')"
            alt="packing unpacking"
            class="service-icon mb-3 mr-0"
          />
          <p>2</p>
        </div>
        <div
          class="worker"
          @click="selectWorker(3)"
        >
          <b-image
            v-if="workers <= 2"
            :src="require('@/assets/technician.png')"
            alt="packing unpacking"
            class="service-icon mb-3 mr-0"
          />
          <b-image
            v-if="workers >= 3"
            :src="require('@/assets/technician-color.png')"
            alt="packing unpacking"
            class="service-icon mb-3 mr-0"
          />
          <p>3</p>
        </div>
      </div>
    </card-component>
    <!-- <b-field label="How many hours?" >
                <b-numberinput
                :controls="false"
                max="4"
                min="0"
                v-model="extElevatorHours"
                @input="changeExtElevatorHours()"
                ></b-numberinput>
            </b-field> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CardComponent from '@/components/CardComponent'
export default {
  name: 'ExteriorElevator',
  components: {
    CardComponent
  },
  data () {
    return {
      extElevatorHours: 0,
      workers: 0
    }
  },
  computed: {
    ...mapGetters('inventory', {
      settings: 'getSettings'
    })
  },
  methods: {
    ...mapActions('inventory', ['setWorkers']),
    changeWorkers () {
      this.setWorkers(this.workers)
    },
    selectWorker (num) {
      this.workers = num
      this.changeWorkers()
    }
  }
}
</script>

<style scoped>
.box-4 {
  margin: 20px;
  padding: 20px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.box-container {
  max-width: 600px;
  display: flex;
  text-align: center;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}
.worker {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}
</style>
