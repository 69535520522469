<template>
  <div>
    <h2
      class="has-text-centered has-text-weight-bold is-size-2 is-size-4-mobile is-uppercase mb-6"
    >
      {{ $t("oninventory.title10") }}
    </h2>
    <card-component class="has-table has-mobile-sort-spaced px-6 py-4 m-3">
      <div class="columns">
        <div class="column">
          <div class="warr-title has-text-weight-bold is-size-5 is-flex">
            <b-checkbox
              v-model="warrantyDefault"
              disabled
              class="is-size-5"
            />{{ $t("oninventory.title11") }} - {{ settings.warrantyDefault }} €
          </div>
          <div class="columns mt-3">
            <div class="column">
              <p>{{ $t("oninventory.paragraph8") }}  </p>
            </div>
          </div>
          <p class="has-text-danger">
            {{ $t("oninventory.paragraph9") }}
          </p>
        </div>
        <div class="column">
          <div class="warr-title has-text-weight-bold is-size-5 is-flex">
            <b-checkbox
              v-model="warrantyExtra"
              class="is-size-5"
              @input="changeWarranty()"
            />
            {{ $t("oninventory.title12") }} - {{ warrantyDefault ? warrantyExtraPriceCalculated + ' €' : '' }}
          </div>
          <div class="columns mt-3">
            <div class="column">
              <b-input v-model="warrantyExtraPrice" @blur="calculateWarranty()" />
            </div>
          </div>
          <p>
            {{ $t("oninventory.paragraph10", {insuranceTotal: warrantyExtraPrice}) }}
          </p>
        </div>
      </div>
    </card-component>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CardComponent from '@/components/CardComponent'
export default {
  name: 'Warranty',
  components: {
    CardComponent
  },
  data () {
    return {
      warrantyDefault: true,
      warrantyExtra: false,
      warrantyExtraPrice: 0,
      warrantyExtraPriceCalculated: 0
    }
  },
  computed: {
    ...mapGetters('inventory', {
      warranty: 'getWarranty',
      settings: 'getSettings'
    }),
    isDissambly () {
      for (const element of this.inventory) {
        if (element.disassembly === true) {
          return true
        }
      }
      return false
    }
  },
  methods: {
    ...mapActions('inventory', ['setWarranty']),
    changeWarranty () {
      this.setWarranty({ status: this.warrantyExtra, price: this.warrantyExtraPriceCalculated})
    },
    calculateWarranty () {
      this.warrantyExtraPriceCalculated = (parseFloat(this.warrantyExtraPrice) * parseFloat(this.settings.warrantyPercentage)) / 100 + parseFloat(this.settings.warrantyFileTax)
      this.changeWarranty()
    }
  }
}
</script>

<style scoped>
.box-4 {
  margin: 20px;
  padding: 20px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
</style>
