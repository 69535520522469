<template>
  <div>
    <h2
      class="
        has-text-centered has-text-weight-bold
        is-size-3 is-size-4-mobile is-uppercase
        mb-4 mt-2
      "
    >
      {{ $t("oninventory.title8") }}
    </h2>
    <card-component class="has-table has-mobile-sort-spaced px-6 py-4 m-3">
      <div class="is-flex is-flex-wrap-wrap">
        <div class="box-container mt-4">
          <p class="has-text-weight-bold is-size-4 mb-2">
            {{ $t("general.departure") }}
          </p>
          <div class="is-flex is-justify-content-center is-align-items-center">
            <b-image
              :src="require('@/assets/lift-truck.png')"
              alt="packing unpacking"
              class="service-icon"
            />
            <b-field label="How many hours?">
              <b-numberinput
                v-model="extElevator.departure.hours"
                :controls="false"
                @input="changeExtElevatorHours()"
              />
            </b-field>
          </div>
        </div>
        <div class="box-container mt-4">
          <p class="has-text-weight-bold is-size-4 mb-2">
            {{ $t("general.destination") }}
          </p>
          <div class="is-flex is-justify-content-center is-align-items-center">
            <b-image
              :src="require('@/assets/lift-truck.png')"
              alt="packing unpacking"
              class="service-icon"
            />
            <b-field label="How many hours?">
              <b-numberinput
                v-model="extElevator.destination.hours"
                :controls="false"
                @input="changeExtElevatorHours()"
              />
            </b-field>
          </div>
        </div>
      </div>
    </card-component>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CardComponent from "@/components/CardComponent";
export default {
  name: "ExteriorElevator",
  components: {
    CardComponent,
  },
  data() {
    return {
      extElevator: {
        departure: {
          status: false,
          hours: 0,
        },
        destination: {
          status: false,
          hours: 0,
        },
      },
    };
  },
  computed: {
    ...mapGetters("inventory", {
      settings: "getSettings",
    }),
  },
  methods: {
    ...mapActions("inventory", ["setExtElevatorHours"]),
    changeExtElevatorHours() {
      if (this.extElevator.departure.hours > 0)
        this.extElevator.departure.status = true;
      else this.extElevator.departure.status = false;
      if (this.extElevator.destination.hours > 0)
        this.extElevator.destination.status = true;
      else this.extElevator.destination.status = false;
      this.setExtElevatorHours(this.extElevator);
    },
  },
};
</script>

<style scoped>
.box-4 {
  margin: 20px;
  padding: 20px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.box-container {
  max-width: 600px;
  display: flex;
  text-align: center;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
