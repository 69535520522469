<template>
  <div>
    <modal-box
      :is-active="isModalActive"
      :trash-object-name="trashObjectName"
      @confirm="trashConfirm"
      @cancel="trashCancel"
    />
    <b-table
      :checked-rows.sync="checkedRows"
      :checkable="checkable"
      :loading="isLoading"
      :paginated="paginated"
      :per-page="perPage"
      :striped="true"
      :hoverable="true"
      default-sort="name"
      :data="inventoryItems"
    >
      <b-button
        type="is-info"
        @click="addInventoryItem()"
      >
        Add
      </b-button>
      <b-table-column
        v-slot="props"
        cell-class="has-no-head-mobile is-image-cell"
      >
        <div class="image">
          <img :src="props.row.icon">
        </div>
      </b-table-column>
      <b-table-column
        v-slot="props"
        label="Name"
        field="name"
        sortable
      >
        {{ props.row.name }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        label="Description"
        field="description"
      >
        {{ props.row.description }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        label="Volume"
        field="volume"
        centered
      >
        {{ props.row.volume }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        label="Price"
        field="price"
        sortable
        centered
      >
        {{ props.row.price }}  €
      </b-table-column>
      <b-table-column
        v-slot="props"
        label="Disassembly"
        field="disassembly"
        sortable
        centered
      >
        {{ props.row.disassembly === 1 ? 'Yes' : 'No' }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        label="Disassembly price"
        field="disassembly_price"
        sortable
        centered
      >
        {{ props.row.disassembly_price }} €
      </b-table-column>
      <b-table-column
        v-slot="props"
        custom-key="actions"
        cell-class="is-actions-cell"
      >
        <div class="buttons is-right">
          <router-link
            :to="{name:'inventoryitems.edit', params: {id: props.row.id}}"
            class="button is-small is-primary"
          >
            <b-icon
              icon="account-edit"
              size="is-small"
            />
          </router-link>
          <button
            class="button is-small is-danger"
            type="button"
            @click.prevent="trashModal(props.row)"
          >
            <b-icon
              icon="trash-can"
              size="is-small"
            />
          </button>
        </div>
      </b-table-column>

      <section
        slot="empty"
        class="section"
      >
        <div class="content has-text-grey has-text-centered">
          <template v-if="isLoading">
            <p>
              <b-icon
                icon="dots-horizontal"
                size="is-large"
              />
            </p>
            <p>Fetching data...</p>
          </template>
          <template v-else>
            <p>
              <b-icon
                icon="emoticon-sad"
                size="is-large"
              />
            </p>
            <p>Nothing's here&hellip;</p>
          </template>
        </div>
      </section>
    </b-table>
  </div>
</template>

<script>
import axios from '@/axios'
import ModalBox from '@/components/ModalBox'

export default {
  name: 'InventoryItemsTable',
  components: { ModalBox },
  props: {
    dataUrl: {
      type: String,
      default: null
    },
    checkable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isModalActive: false,
      trashObject: null,
      inventoryItems: [],
      isLoading: false,
      paginated: false,
      perPage: 10,
      checkedRows: []
    }
  },
  computed: {
    trashObjectName () {
      if (this.trashObject) {
        return this.trashObject.name
      }

      return null
    }
  },
  mounted () {
    this.getItems()
  },
  methods: {
    trashModal (trashObject) {
      this.trashObject = trashObject
      this.isModalActive = true
    },
    trashConfirm () {
      axios
        .delete(`inventoryitems/${this.trashObject.id}`)
        .then(() => {
          this.getItems()
          this.isModalActive = false
          this.$buefy.snackbar.open({
            message: 'Confirmed',
            queue: false
          })
        })
        .catch((e) => {
          this.isLoading = false
          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: 'is-danger'
          })
        })
    },
    trashCancel () {
      this.isModalActive = false
    },
    addInventoryItem () {
      this.$router.push('inventoryitems/add')
    },
    getItems () {
      if (this.dataUrl) {
        this.isLoading = true
        axios
          .get(this.dataUrl)
          .then((result) => {
            this.isLoading = false
            if (result.data) {
              if (result.data.length > this.perPage) {
                this.paginated = true
              }
              this.inventoryItems = [...result.data]
            }
          })
          .catch((e) => {
            this.isLoading = false
            this.$buefy.toast.open({
              message: `Error: ${e.message}`,
              type: 'is-danger'
            })
          })
      }
    }
  }
}
</script>

<style scoped>
.image {
  max-width: 50px;
}
</style>
