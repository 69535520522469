<template>
  <div>
    <h2
      class="has-text-centered has-text-weight-bold is-size-2 is-size-4-mobile is-uppercase mb-6"
    >
      {{ $t("ontime.hours") }}
    </h2>
    <card-component class="has-table has-mobile-sort-spaced px-6 py-4 m-3">
      <div class="box-container">
        <div class="is-flex">
          <div
            class="hour"
            @click="selecthour(1)"
          >
            <b-image
              v-if="hours === 0"
              :src="require('@/assets/hour.png')"
              alt="packing unpacking"
              class="service-icon mb-3 mr-0"
            />
            <b-image
              v-if="hours > 0"
              :src="require('@/assets/hour-color.png')"
              alt="packing unpacking"
              class="service-icon mb-3 mr-0"
            />
            <p>2h</p>
          </div>
          <div
            class="hour"
            @click="selecthour(2)"
          >
            <b-image
              v-if="hours <= 1"
              :src="require('@/assets/hour.png')"
              alt="packing unpacking"
              class="service-icon mb-3 mr-0"
            />
            <b-image
              v-if="hours >= 2"
              :src="require('@/assets/hour-color.png')"
              alt="packing unpacking"
              class="service-icon mb-3 mr-0"
            />
            <p>4h</p>
          </div>
          <div
            class="hour"
            @click="selecthour(3)"
          >
            <b-image
              v-if="hours <= 2"
              :src="require('@/assets/hour.png')"
              alt="packing unpacking"
              class="service-icon mb-3 mr-0"
            />
            <b-image
              v-if="hours >= 3"
              :src="require('@/assets/hour-color.png')"
              alt="packing unpacking"
              class="service-icon mb-3 mr-0"
            />
            <p>8h</p>
          </div>
        </div>
        <p class="has-text-danger">
          {{ $t("ontime.addhours") }} -
          {{ parseFloat(settings.additionalHour) }} € /
          {{ $t("ontime.personhour") }}
        </p>
      </div>
    </card-component>
    <!-- <b-field label="How many hours?" >
                <b-numberinput
                :controls="false"
                max="4"
                min="0"
                v-model="extElevatorHours"
                @input="changeExtElevatorHours()"
                ></b-numberinput>
            </b-field> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CardComponent from '@/components/CardComponent'
export default {
  name: 'ExteriorElevator',
  components: {
    CardComponent
  },
  data () {
    return {
      extElevatorHours: 0,
      hours: 0
    }
  },
  computed: {
    ...mapGetters('inventory', {
      settings: 'getSettings'
    })
  },
  methods: {
    ...mapActions('inventory', ['setHours']),
    changehours () {
      if (this.hours === 1) this.setHours(2)
      if (this.hours === 2) this.setHours(4)
      if (this.hours === 3) this.setHours(8)
    },
    selecthour (num) {
      this.hours = num
      this.changehours()
    }
  }
}
</script>

<style scoped>
.box-4 {
  margin: 20px;
  padding: 20px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.box-container {
  max-width: 600px;
  display: flex;
  text-align: center;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.hour {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  margin: 10px;
}
</style>
