<template>
  <div>
    <h2
      class="has-text-weight-bold has-text-centered is-size-2 is-size-4-mobile is-uppercase"
    >
      {{ $t("oninventory.title3") }}
    </h2>
    <p class="has-text-centered mb-5">
      {{ $t("oninventory.paragraph3") }}
    </p>
    <p
      v-if="!isDissambly"
      class="has-text-centered"
    >
      {{ $t("oninventory.paragraph4") }}
    </p>
    <card-component
      v-if="isDissambly"
      class="has-table has-mobile-sort-spaced px-6 py-4 m-3"
    >
      <div
        v-for="item in inventory"
        :key="item.id"
      >
        <InventoryItemDissambly
          v-if="item.disassembly === 1"
          :element="item"
        />
      </div>
    </card-component>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import InventoryItemDissambly from './InventoryItemDissambly'
import CardComponent from '@/components/CardComponent'
export default {
  name: 'Dissambly',
  components: {
    InventoryItemDissambly,
    CardComponent
  },
  computed: {
    ...mapState({
      inventory: (state) => state.inventory
    }),
    ...mapGetters('inventory', {
      inventory: 'getInventory'
    }),
    isDissambly () {
      for (const element of this.inventory) {
        if (element.disassembly === 1) {
          return true
        }
      }
      return false
    }
  }
}
</script>
<style scoped>
</style>
