<template>
  <div>
    <hero-bar>
      Orders
    </hero-bar>
    <section class="section is-main-section">
      <card-component
        class="has-table has-mobile-sort-spaced"
        title="Orders"
        icon="account-multiple"
      >
        <OrdersTable
          :data-url="`orders`"
          :checkable="false"
        />
      </card-component>
    </section>
  </div>
</template>

<script>
// import Notification from '@/components/Notification'
import OrdersTable from '@/components/OrdersTable'
import CardComponent from '@/components/CardComponent'
import HeroBar from '@/components/HeroBar'
export default {
  name: 'Orders',
  components: {
    HeroBar,
    CardComponent,
    OrdersTable
  },
  computed: {
  }
}
</script>
