<template>
  <div>
    <h2
      class="has-text-centered has-text-weight-bold is-size-2 is-size-4-mobile is-uppercase"
    >
      {{ $t("oninventory.title13") }}
    </h2>
    <p class="has-text-centered is-size-4 is-size-5-mobile mb-6">
      {{ $t("oninventory.paragraph14") }}
    </p>
    <card-component class="has-table has-mobile-sort-spaced px-6 py-4 m-3">
      <div class="columns">
        <div class="column">
          <div
            class="warr-title has-text-weight-bold is-size-4 is-size-5-mobile is-flex-mobile"
          >
            <b-checkbox
              v-model="cancellationDefault"
              disabled
              class="is-size-5"
            />{{ $t("oninventory.paragraph15") }}
          </div>
        </div>
        <div class="column">
          <div
            class="warr-title has-text-weight-bold is-size-4 is-size-5-mobile is-flex-mobile"
          >
            <b-checkbox
              v-model="cancellationExtra"
              class="is-size-5"
              @input="changeCancellation()"
            />
            {{ $t("oninventory.paragraph16") }} {{ cancellation.price }} €
          </div>
        </div>
      </div>
      <b-image
        :src="require('@/assets/annulation.png')"
        alt="Cancellation"
        class="mt-5"
        v-if="this.$i18n.locale === 'en'"
      />
      <b-image
        :src="require('@/assets/annulation-fr.png')"
        alt="Annulation"
        class="mt-5"
        v-if="this.$i18n.locale === 'fr'"
      />
    </card-component>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CardComponent from "@/components/CardComponent";
export default {
  name: "Cancellation",
  components: {
    CardComponent,
  },
  data() {
    return {
      cancellationDefault: true,
      cancellationExtra: false,
    };
  },
  computed: {
    ...mapGetters("inventory", {
      cancellation: "getCancellation",
      settings: "getSettings",
    }),
  },
  methods: {
    ...mapActions("inventory", ["setCancellation"]),
    changeCancellation() {
      this.setCancellation(this.cancellationExtra);
    },
  },
};
</script>

<style scoped>
.box-4 {
  margin: 20px;
  padding: 20px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
</style>
