<template>
  <div>
    <h2
      class="has-text-centered has-text-weight-bold is-size-2 is-size-4-mobile is-uppercase mb-6"
    >
      {{ $t("ontime.trucks") }}
    </h2>
    <card-component class="has-table has-mobile-sort-spaced px-6 py-4 m-3">
      <div class="box-container">
        <div
          class="truck"
          @click="selectTruck(1)"
        >
          <b-image
            v-if="trucks !== 1"
            :src="require('@/assets/truck.png')"
            alt="packing unpacking"
            class="service-icon mb-3 mr-0"
          />
          <b-image
            v-if="trucks === 1"
            :src="require('@/assets/truck-color.png')"
            alt="packing unpacking"
            class="service-icon mb-3 mr-0"
          />
          <p>12m<sup>3</sup></p>
        </div>
        <div
          class="truck"
          @click="selectTruck(2)"
        >
          <b-image
            v-if="trucks !== 2"
            :src="require('@/assets/truck.png')"
            alt="packing unpacking"
            class="service-icon mb-3 mr-0"
          />
          <b-image
            v-if="trucks === 2"
            :src="require('@/assets/truck-color.png')"
            alt="packing unpacking"
            class="service-icon mb-3 mr-0"
          />
          <p>22m<sup>3</sup></p>
          <b-field v-if="trucks === 2">
            <b-radio-button v-model="truckNumber"
            @click="changeTruckNumber"
                native-value="1"
                type="is-primary is-light is-outlined">
                1
            </b-radio-button>
            <b-radio-button v-model="truckNumber"
            @click="changeTruckNumber"
                native-value="2"
                type="is-primary is-light is-outlined">
                2
            </b-radio-button>
        </b-field>
        </div>
      </div>
    </card-component>
    <!-- <b-field label="How many hours?" >
                <b-numberinput
                :controls="false"
                max="4"
                min="0"
                v-model="extElevatorHours"
                @input="changeExtElevatorHours()"
                ></b-numberinput>
            </b-field> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CardComponent from '@/components/CardComponent'
export default {
  name: 'ExteriorElevator',
  components: {
    CardComponent
  },
  data () {
    return {
      extElevatorHours: 0,
      trucks: 0,
      truckNumber: 1
    }
  },
  computed: {
    ...mapGetters('inventory', {
      settings: 'getSettings'
    })
  },
  watch: {
    truckNumber () {
      this.changeTruckNumber()
    }
  },
  methods: {
    ...mapActions('inventory', ['setTruck', 'setTruckNumber']),
    changeTrucks () {
      if (this.trucks === 1) this.setTruck(12)
      if (this.trucks === 2) this.setTruck(22)
      if (this.trucks === 3) this.setTruck(40)
    },
    changeTruckNumber () {
      console.log(this.truckNumber)
      this.setTruckNumber(this.truckNumber)
    },
    selectTruck (num) {
      this.trucks = num
      this.changeTrucks()
    }
  }
}
</script>

<style scoped>
.box-4 {
  margin: 20px;
  padding: 20px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.box-container {
  max-width: 600px;
  display: flex;
  text-align: center;
  margin: 0 auto;
  align-items: flex-start;
  justify-content: center;
}
.truck {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: pointer;
  margin: 10px;
  
}
</style>
