<template>
  <div class="material-container">
    <img :src="element.image" alt="Material Item" class="img-container" />
    <p class="py-3 mt-1 has-text-weight-bold">
      {{ $i18n.locale === "en" ? element.name : element.name_fr }} - {{ element.price }} €
    </p>
    <div v-if="element.description">
      <p v-html="element.description"></p>
    </div>
    <div class="columns is-centered is-align-items-center">
      <div class="column is-three-quarters">
        <b-numberinput
          v-model="elementCounter"
          type="is-info"
          controls-rounded
          min="0"
          max="200"
          @input="addMaterial()"
        />
      </div>
      <!-- <div class="column">
        <p>{{ element.price }} €</p>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "MaterialItem",
  props: {
    element: Object,
  },
  data() {
    return {
      elementCounter: 0,
    };
  },
  methods: {
    ...mapActions("inventory", ["setItemMaterialQuantity"]),
    addMaterial() {
      this.setItemMaterialQuantity({
        id: this.element.id,
        quantity: this.elementCounter,
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.material-container {
  margin: 10px;
  padding: 20px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  width: 300px;
}

.img-container {
  object-fit: cover;
  height: 200px;
  width: 100%;
}

.img-container img {
  height: 200px !important;
}

@media only screen and (max-width: 768px) {
  .material-container {
    height: unset;
    min-height: 350px;
  }
}
</style>
