<template>
  <div>
    <h2
      class="
        has-text-centered has-text-weight-bold
        is-size-2 is-size-4-mobile is-uppercase
      "
    >
      {{ $t("oninventory.title16") }}
    </h2>

    <card-component
      id="printMe"
      class="has-table has-mobile-sort-spaced px-3 py-4 m-3 has-text-centered"
    >
      <div class="recap-container has-text-left">
        <p
          v-if="inventory.moveDate"
          class="has-text-weight-bold"
        >
          {{ $t("general.movingdate") }}:
          <span class="has-text-weight-normal">{{
            inventory.moveDate
              ? format(new Date(inventory.moveDate), "dd-MM-yyyy hh:mm")
              : ""
          }}</span>
        </p>
        <p
          v-if="inventory.deliveryDate"
          class="has-text-weight-bold"
        >
          {{ $t("general.deliverydate") }}:
          <span class="has-text-weight-normal">{{
            inventory.deliveryDate
              ? format(new Date(inventory.deliveryDate), "dd-MM-yyyy hh:mm")
              : ""
          }}</span>
        </p>
        <p
          v-if="inventory.pickupDate"
          class="has-text-weight-bold"
        >
          {{ $t("general.pickupdate") }}:
          <span class="has-text-weight-normal">{{
            inventory.pickupDate
              ? format(new Date(inventory.pickupDate), "dd-MM-yyyy hh:mm")
              : ""
          }}</span>
        </p>
      </div>

      <p
        class="
          has-text-left has-text-weight-bold
          is-size-4
          recap-container
          mt-4
        "
      >
        {{ $t("general.departureinfo") }}
      </p>
      <div class="recap-container has-text-left">
        <p
          v-if="inventory.departure.street"
          class="has-text-weight-bold"
        >
          {{ $t("oninventory.paragraph17") }}:
          <span
            class="has-text-weight-normal"
          >{{ inventory.departure.street }},
            {{ inventory.departure.postalCode }},
            {{ inventory.departure.city }}</span>
        </p>
        <p
          v-if="inventory.departure.type"
          class="has-text-weight-bold"
        >
          {{ $t("oninventory.paragraph21") }}:
          <span class="has-text-weight-normal">{{
            inventory.departure.type
          }}</span>
        </p>
        <p
          v-if="inventory.departure.area"
          class="has-text-weight-bold"
        >
          {{ $t("oninventory.paragraph24") }}:
          <span class="has-text-weight-normal">{{
            inventory.departure.area
          }}</span>
        </p>
        <p
          v-if="inventory.departure.floor"
          class="has-text-weight-bold"
        >
          {{ $t("oninventory.paragraph25") }}:
          <span class="has-text-weight-normal">{{
            inventory.departure.floor
          }}</span>
        </p>
        <p
          v-if="inventory.departure.distance"
          class="has-text-weight-bold"
        >
          {{ $t("oninventory.paragraph28") }}:
          <span class="has-text-weight-normal">{{
            inventory.departure.distance
          }}</span>
        </p>
        <p
          v-if="inventory.departure.elevator"
          class="has-text-weight-bold"
        >
          {{ $t("oninventory.paragraph29") }}:
          <span class="has-text-weight-normal">{{
            inventory.departure.elevator
          }}</span>
        </p>
        <p
          v-if="inventory.departure.elevatorUse"
          class="has-text-weight-bold"
        >
          {{ $t("oninventory.paragraph30") }}:
          <span class="has-text-weight-normal">{{
            inventory.departure.elevatorUse
          }}</span>
        </p>
        <p
          v-if="inventory.departure.closet"
          class="has-text-weight-bold"
        >
          {{ $t("oninventory.paragraph31") }}:
          <span class="has-text-weight-normal">{{
            inventory.departure.closet
          }}</span>
        </p>
      </div>
      <p
        v-if="currentRouteName === 'OnInventory'"
        class="
          has-text-left has-text-weight-bold
          is-size-4
          recap-container
          mt-4
        "
      >
        {{ $t("general.arrivalinfo") }}
      </p>
      <div class="recap-container has-text-left">
        <p
          v-if="inventory.destination.street"
          class="has-text-weight-bold"
        >
          {{ $t("oninventory.paragraph17") }}:
          <span
            class="has-text-weight-normal"
          >{{ inventory.destination.street }},
            {{ inventory.destination.postalCode }},
            {{ inventory.destination.city }}</span>
        </p>
        <p
          v-if="inventory.destination.type"
          class="has-text-weight-bold"
        >
          {{ $t("oninventory.paragraph21") }}:
          <span class="has-text-weight-normal">{{
            inventory.destination.type
          }}</span>
        </p>
        <p
          v-if="inventory.destination.area"
          class="has-text-weight-bold"
        >
          {{ $t("oninventory.paragraph24") }}:
          <span class="has-text-weight-normal">{{
            inventory.destination.area
          }}</span>
        </p>
        <p
          v-if="inventory.destination.floor"
          class="has-text-weight-bold"
        >
          {{ $t("oninventory.paragraph25") }}:
          <span class="has-text-weight-normal">{{
            inventory.destination.floor
          }}</span>
        </p>
        <p
          v-if="inventory.destination.distance"
          class="has-text-weight-bold"
        >
          {{ $t("oninventory.paragraph28") }}:
          <span class="has-text-weight-normal">{{
            inventory.destination.distance
          }}</span>
        </p>
        <p
          v-if="inventory.destination.elevator"
          class="has-text-weight-bold"
        >
          {{ $t("oninventory.paragraph29") }}:
          <span class="has-text-weight-normal">{{
            inventory.destination.elevator
          }}</span>
        </p>
        <p
          v-if="inventory.destination.elevatorUse"
          class="has-text-weight-bold"
        >
          {{ $t("oninventory.paragraph30") }}:
          <span class="has-text-weight-normal">{{
            inventory.destination.elevatorUse
          }}</span>
        </p>
        <p
          v-if="inventory.destination.closet"
          class="has-text-weight-bold"
        >
          {{ $t("oninventory.paragraph31") }}:
          <span class="has-text-weight-normal">{{
            inventory.destination.closet
          }}</span>
        </p>
      </div>
      <p
        class="
          has-text-left has-text-weight-bold
          is-size-4
          recap-container
          mt-4
        "
      >
        {{ $t("general.servicesnbenefits") }}
      </p>
      <div class="recap-container has-text-left">
        <p
          v-if="inventory.cleaningHours !== 0"
          class="has-text-weight-bold"
        >
          {{ $t("general.cleaninghours") }}:
          <span class="has-text-weight-normal">{{
            inventory.cleaningHours
          }}</span>
        </p>
        <p
          v-if="inventory.packingUnpackingHours !== 0"
          class="has-text-weight-bold"
        >
          {{ $t("general.packingunpackinghours") }}:
          <span class="has-text-weight-normal">{{
            inventory.packingUnpackingHours
          }}</span>
        </p>
        <p class="has-text-weight-bold">
          {{ $t("general.parkingpermit") }} {{ $t("general.departure") }} -
          {{ inventory.parkingPermit.departure.status ? "Yes" : "No" }}
          <span class="has-text-weight-normal">
            <b-icon
              v-if="!inventory.parkingPermit.departure.status"
              icon="close"
              type="is-danger"
            />
            <b-icon
              v-if="inventory.parkingPermit.departure.status"
              icon="check"
              type="is-success"
            /></span>
        </p>
        <p class="has-text-weight-bold">
          {{ $t("general.parkingpermit") }} {{ $t("general.destination") }} -
          {{ inventory.parkingPermit.destination.status ? "Yes" : "No" }}
          <span class="has-text-weight-normal">
            <b-icon
              v-if="!inventory.parkingPermit.destination.status"
              icon="close"
              type="is-danger"
            />
            <b-icon
              v-if="inventory.parkingPermit.destination.status"
              icon="check"
              type="is-success"
            /></span>
        </p>
        <p class="has-text-weight-bold">
          {{ $t("general.liftAuthorisation") }} {{ $t("general.departure") }} -
          {{ inventory.liftAuthorisation.departure.status ? "Yes" : "No" }}
          <span class="has-text-weight-normal">
            <b-icon
              v-if="!inventory.liftAuthorisation.departure.status"
              icon="close"
              type="is-danger"
            />
            <b-icon
              v-if="inventory.liftAuthorisation.departure.status"
              icon="check"
              type="is-success"
            /></span>
        </p>
        <p class="has-text-weight-bold">
          {{ $t("general.liftAuthorisation") }} {{ $t("general.destination") }} -
          {{ inventory.liftAuthorisation.destination.status ? "Yes" : "No" }}
          <span class="has-text-weight-normal">
            <b-icon
              v-if="!inventory.liftAuthorisation.destination.status"
              icon="close"
              type="is-danger"
            />
            <b-icon
              v-if="inventory.liftAuthorisation.destination.status"
              icon="check"
              type="is-success"
            /></span>
        </p>
        <p class="has-text-weight-bold">
          {{ $t("general.elevator") }} {{ $t("general.departure") }} -
          {{
            !inventory.extElevator.departure.status
              ? "No"
              : inventory.extElevator.departure.hours
          }}
          {{
            !inventory.extElevator.departure.status ? "" : $t("general.hours")
          }}
          <span class="has-text-weight-normal">
            <b-icon
              v-if="!inventory.extElevator.departure.status"
              icon="close"
              type="is-danger"
            />
            <b-icon
              v-if="inventory.extElevator.departure.status"
              icon="check"
              type="is-success"
            /></span>
        </p>
        <p class="has-text-weight-bold">
          {{ $t("general.elevator") }} {{ $t("general.destination") }} -
          {{
            !inventory.extElevator.destination.status
              ? "No"
              : inventory.extElevator.destination.hours
          }}
          {{
            !inventory.extElevator.destination.status ? "" : $t("general.hours")
          }}
          <span class="has-text-weight-normal">
            <b-icon
              v-if="!inventory.extElevator.destination.status"
              icon="close"
              type="is-danger"
            />
            <b-icon
              v-if="inventory.extElevator.destination.status"
              icon="check"
              type="is-success"
            /></span>
        </p>
        <p
          v-if="!inventory.warranty.status"
          class="has-text-weight-bold"
        >
          {{ $t("oninventory.title11") }}
          <span class="has-text-weight-normal">
            <b-icon
              icon="check"
              type="is-success"
            /></span>
        </p>
        <p
          v-if="inventory.warranty.status === true"
          class="has-text-weight-bold"
        >
          {{ $t("oninventory.title12") }}
          <span class="has-text-weight-normal">
            <b-icon
              icon="check"
              type="is-success"
            /></span>
        </p>
        <p
          v-if="!inventory.cancellation.status"
          class="has-text-weight-bold"
        >
          {{ $t("oninventory.paragraph15") }}
          <span class="has-text-weight-normal">
            <b-icon
              icon="check"
              type="is-success"
            /></span>
        </p>
        <p
          v-if="inventory.cancellation.status"
          class="has-text-weight-bold"
        >
          {{ $t("oninventory.paragraph16") }}
          <span class="has-text-weight-normal">
            <b-icon
              icon="check"
              type="is-success"
            /></span>
        </p>
        <p
          v-if="currentRouteName === 'OnTime'"
          class="has-text-weight-bold"
        >
          {{ $t("general.movers") }} - {{ inventory.workers
          }}<span class="has-text-weight-normal">
            <b-icon
              icon="check"
              type="is-success"
            /></span>
        </p>
        <p
          v-if="currentRouteName === 'OnTime'"
          class="has-text-weight-bold"
        >
          {{ $t("general.trucksize") }} - {{ inventory.truck }}m<sup>2</sup>
          {{ inventory.truck === 22 ? inventory.truckNnumber : '' }}
          <span class="has-text-weight-normal">
            <b-icon
              icon="check"
              type="is-success"
            /></span>
        </p>
        <p
          v-if="currentRouteName === 'OnTime'"
          class="has-text-weight-bold has-text-capitalize"
        >
          {{ $t("general.hours") }} - {{ inventory.hours }}h<span
            class="has-text-weight-normal"
          >
            <b-icon
              icon="check"
              type="is-success"
            /></span>
        </p>
      </div>
      <p
        v-if="currentRouteName === 'OnInventory'"
        class="
          has-text-left has-text-weight-bold
          is-size-4
          recap-container
          mt-4
        "
      >
        {{ $t("general.inventory") }}
      </p>
      <div class="recap-container">
        <div class="has-text-left">
          <p
            v-for="element in itemsInv"
            :key="element.id"
            class="is-flex p-1"
          >
            <img
              :src="element.icon"
              class="icon is-medium mr-3"
              alt=""
            >
            {{ $i18n.locale === "en" ? element.name : element.name_fr }} -
            <span>{{ element.quantity }}</span>
          </p>
        </div>
      </div>
      <p
        v-if="dissamblyItems.length !== 0"
        class="
          has-text-left has-text-weight-bold
          is-size-4
          recap-container
          mt-4
        "
      >
        {{ $t("general.assembling") }}
      </p>
      <div class="recap-container">
        <div class="has-text-left">
          <p
            v-for="element in dissamblyItems"
            :key="'B' + element.id"
            class="is-flex p-1"
          >
            <span v-if="element.disassembly_quantity > 0">
              <img
                :src="element.icon"
                class="icon is-medium mr-3"
                alt=""
              >
              {{ $i18n.locale === "en" ? element.name : element.name_fr }} -
              <span v-if="element.disassembly_quantity > 0">
                {{ element.disassembly_quantity }}
              </span>
            </span>
          </p>
        </div>
      </div>
      <!-- <div class="has-text-right pr-2 pt-5">
        <p class="is-size-5">
          {{ $t("general.total") }}:
          <strong class="is-size-4 has-text-weight-bold has-text-success">
            {{ total.toFixed(2) }} €
          </strong>
        </p>
        <p class="is-size-5">
          {{ $t("general.total") }} + {{ settings.VAT }}% VAT:
          <strong class="is-size-4 has-text-weight-bold has-text-success">
            {{ totalInclTax.toFixed(2) }}
            €
          </strong>
        </p>
      </div> -->
      <b-button
        type="is-success has-text-right"
        @click="print"
      >
        {{ $t("general.print") }}
      </b-button>
    </card-component>
  </div>
</template>

<script>
import { format } from 'date-fns'
import { mapGetters, mapActions } from 'vuex'
import CardComponent from '@/components/CardComponent'
export default {
  name: 'Recapitualatory',
  components: {
    CardComponent
  },
  data () {
    return {
      format
    }
  },
  computed: {
    ...mapGetters('inventory', {
      inventory: 'getState',
      total: 'getOrderTotal',
      settings: 'getSettings',
      totalInclTax: 'getOrderTotalInclTax'
    }),

    dissamblyItems () {
      return this.itemsInv.filter((item) => item.disassembly_quantity !== 0)
    },
    itemsInv () {
      return this.inventory.inventory.filter((i) => i.quantity !== 0)
    },
    currentRouteName () {
      return this.$route.name
    }
  },
  methods: {
    ...mapActions('inventory', ['addItemToInventory']),
    print () {
      // Pass the element id here
      this.$htmlToPaper('printMe')
    }
  }
}
</script>
<style scoped>
.recap-container {
  max-width: 600px;
  text-align: center;
  margin: 0 auto;
}
.icon-inventory {
  max-width: 18px;
  max-height: 24px;
}
</style>
