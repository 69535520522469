<template>
  <div>
    <h2
      class="has-text-weight-bold has-text-centered is-size-2 is-size-4-mobile is-uppercase"
    >
      {{ $t("oninventory.title2") }}
    </h2>
    <p class="has-text-centered mb-5">
      {{ $t("oninventory.paragraph2") }}
    </p>
    <b-datepicker
      v-model="date"
      class="has-text-centered"
      :min-date="minDate"
      :unselectable-dates="unselectableDates"
      inline
      @input="setDate()"
    />
    <b-timepicker
      v-model="time"
      class="has-text-centered mt-2"
      :min-time="min"
      :max-time="max"
      :increment-minutes="incrementMinutes"
      inline
      @input="setDate()"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import axios from "@/axios";
export default {
  name: "Schedule",
  data() {
    const today = new Date();
    const min = new Date();
    min.setHours(9);
    min.setMinutes(0);
    const max = new Date();
    max.setHours(20);
    max.setMinutes(0);

    // const notDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7)

    return {
      date: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 1
      ),
      time: min,
      min: min,
      max: max,
      incrementMinutes: 15,
      minDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      timepicker: {
        incrementMinutes: 15,
        time: min,
        minTime: min,
        maxTime: max,
      },
      unselectableDates: [],
    };
  },
  created() {
    // const today = new Date()
    // const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 4)
    // this.datepicker.unselectableDates.push(tomorrow)
  },
  mounted() {
    // axios
    //   .get("orders")
    //   .then((result) => {
    //     for (const element of result.data) {
    //       const dates = JSON.parse(element.dates);
    //       this.unselectableDates.push(new Date(dates.moveDate));
    //     }
    //   })
    //   .catch((e) => {
    //     this.isLoading = false;
    //     this.$buefy.toast.open({
    //       message: `Error: ${e.message}`,
    //       type: "is-danger",
    //     });
    //   });
  },
  methods: {
    ...mapActions("inventory", ["setMoveDate"]),
    setDate() {
      const datetime = new Date(
        this.date.getFullYear(),
        this.date.getMonth(),
        this.date.getDate(),
        this.time.getHours(),
        this.time.getMinutes(),
        this.time.getSeconds()
      );
      this.setMoveDate(datetime)
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
