<template>
  <div>
    <h2
      class="
        has-text-centered has-text-weight-bold
        is-size-4 is-size-4-mobile is-uppercase
        mb-4 mt-2
      "
    >
      {{ $t("oninventory.title9") }}
    </h2>
    <card-component class="has-table has-mobile-sort-spaced px-6 py-4 m-3">
      <div class="is-flex is-flex-wrap-wrap">
        <div class="box-container mt-4">
          <p class="has-text-weight-bold is-size-4 mb-2">
            {{ $t("general.departure") }}
          </p>
          <div class="is-flex is-justify-content-center is-align-items-center">
            <b-image
              :src="require('@/assets/parking.png')"
              alt="packing unpacking"
              class="service-icon"
            />
            <b-field class="mt-4">
              <b-switch
                v-model="parkingPermit.departure.status"
                true-value="Yes"
                false-value="No"
                @input="changeParkingPermit()"
              >
                {{ parkingPermit.departure.status }}
              </b-switch>
            </b-field>
          </div>
        </div>
        <div class="box-container mt-4">
          <p class="has-text-weight-bold is-size-4 mb-2">
            {{ $t("general.destination") }}
          </p>
          <div class="is-flex is-justify-content-center is-align-items-center">
            <b-image
              :src="require('@/assets/parking.png')"
              alt="packing unpacking"
              class="service-icon"
            />
            <b-field class="mt-4">
              <b-switch
                v-model="parkingPermit.destination.status"
                true-value="Yes"
                false-value="No"
                @input="changeParkingPermit()"
              >
                {{ parkingPermit.destination.status }}
              </b-switch>
            </b-field>
          </div>
        </div>
      </div>
    </card-component>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CardComponent from '@/components/CardComponent'
export default {
  name: 'ParkingPermit',
  components: {
    CardComponent
  },
  data () {
    return {
      parkingPermit: {
        destination: {
          status: 'No'
        },
        departure: {
          status: 'No'
        }
      }
    }
  },
  computed: {
    ...mapGetters('inventory', {
      settings: 'getSettings'
    })
  },
  methods: {
    ...mapActions('inventory', ['setParkingPermit']),
    changeParkingPermit () {
      const parkingPermit = {
        destination: {
          status: false,
          price: parseFloat(this.settings.parkingPermitPrice)
        },
        departure: {
          status: false,
          price: parseFloat(this.settings.parkingPermitPrice)
        }
      }
      if (this.parkingPermit.destination.status === 'Yes') {
        parkingPermit.destination.status = true
      } else {
        parkingPermit.destination.status = false
      }
      if (this.parkingPermit.departure.status === 'Yes') {
        parkingPermit.departure.status = true
      } else {
        parkingPermit.departure.status = false
      }

      this.setParkingPermit(parkingPermit)
    }
  }
}
</script>

<style scoped>
.box-4 {
  margin: 20px;
  padding: 20px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.box-container {
  max-width: 600px;
  display: flex;
  text-align: center;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
