<template>
  <div class="columns is-centered is-align-items-center">
    <div class="column is-1">
      <img
        class="icons"
        :src="element.icon"
      >
    </div>
    <div class="column">
      {{ $i18n.locale === "en" ? element.name : element.name_fr }}
    </div>
    <div class="btn-plusminus">
      <b-numberinput
        v-model="quantity"
        type="is-info"
        controls-rounded
        min="0"
        max="500"
        @input="changeQuantity()"
      />
    </div>
    <div class="column is-1 has-text-right">
      <v-icon
        name="trash"
        class="btn-delete"
        @click="deleteItemFromInventory(element)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'InventoryItemAdded',
  props: {
    element: Object
  },
  data () {
    return {
      quantity: 1
    }
  },
  methods: {
    ...mapActions('inventory', [
      'incrementItemQuantity',
      'decrementItemQuantity',
      'deleteItemFromInventory',
      'setItemQuantity'
    ]),
    changeQuantity () {
      this.setItemQuantity({
        id: this.element.id,
        quantity: this.quantity
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn-delete {
  cursor: pointer;
  font-size: 18px;
}
.btn-delete:hover {
  font-size: 24px;
}
.icons {
  max-width: 48px;
}

.btn-plusminus {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-plusminus button {
  margin: 10px;
}

@media screen and (max-width: 768px) {
  .columns {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
