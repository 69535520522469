var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[(_vm.currentRouteName === 'OnInventory')?_c('div',[_c('OnInventory')],1):_vm._e(),(_vm.currentRouteName === 'OnTime')?_c('div',[_c('OnTime')],1):_vm._e(),(_vm.currentRouteName === 'Calculator')?_c('div',[_c('Calculator')],1):_vm._e(),(_vm.currentRouteName === 'ThankYou')?_c('div',[_c('ThankYou')],1):_vm._e(),(_vm.currentRouteName === 'login')?_c('div',[_c('Login')],1):_vm._e(),(
      _vm.currentRouteName !== 'OnInventory' &&
      _vm.currentRouteName !== 'OnTime' &&
      _vm.currentRouteName !== null &&
      _vm.currentRouteName !== 'ThankYou' &&
      _vm.currentRouteName !== 'login' &&
      _vm.currentRouteName !== 'Calculator'
    )?_c('div',{staticClass:"has-aside-left p-14rem has-navbar-fixed-top"},[_c('nav-bar'),_c('aside-menu',{attrs:{"menu":_vm.menu}}),_c('router-view'),_c('footer-bar')],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }