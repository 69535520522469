/* Styles */
import '@/scss/main.scss'

/* Core */
import Vue from 'vue'
import Buefy from 'buefy'

/* Router & Store */
import router from './router'
import { store } from './store'

/* Service Worker */
import './registerServiceWorker'

/* Vue. Main component */
import App from './App.vue'

import '@mdi/font/css/materialdesignicons.css'
import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon'
import VueHtmlToPaper from 'vue-html-to-paper'
import i18n from './i18n'

/* Default title tag */
const defaultDocumentTitle = '- GreenMovers'

/* Collapse mobile aside menu on route change & set document title from route meta */
router.afterEach(to => {
  store.commit('asideMobileStateToggle', false)

  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} — ${defaultDocumentTitle}`
  } else {
    document.title = defaultDocumentTitle
  }
})

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://unpkg.com/buefy/dist/buefy.min.css',
    'https://cdn.jsdelivr.net/npm/@mdi/font@5.8.55/css/materialdesignicons.min.css',
    './main.css'
  ]
}

Vue.config.productionTip = false

Vue.component('VIcon', Icon)

Vue.use(Buefy)

Vue.use(VueHtmlToPaper, options)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
