<template>
  <div class="display:flex; flex-direction:column; justify-content:center">
    <h2
      class="has-text-centered has-text-weight-bold is-size-2 is-size-4-mobile is-uppercase"
    >
      {{ $t("oninventory.paragraph17") }}
    </h2>
    <card-component
      v-if="currentRouteName === 'OnInventory'"
      class="has-table has-mobile-sort-spaced px-3 py-4 m-3 has-text-centered"
    >
      <p class="is-size-3 is-size-5-mobile has-text-weight-bold">
        {{ $t("oninventory.title14") }}
      </p>
      <div class="address-container is-flex-direction-column">
        <b-field
          grouped
          expanded
          :label="$t('oninventory.paragraph18')"
          position="is-centered"
          class="is-flex-direction-column"
        >
          <b-input
            v-model="departure.address"
            expanded
            @blur="setAddressToState()"
          />
        </b-field>
        <b-field
          grouped
          position="is-centered"
        >
          <b-field :label="$t('oninventory.paragraph25')">
            <b-input
              v-model="departure.floor"
              type="number"
              :controls="false"
              expanded
              @blur="setAddressToState()"
            />
          </b-field>
          <b-field
            :label="$t('oninventory.paragraph28')"
            expanded
          >
            <b-select
              v-model="departure.distance"
              placeholder="Select a distance"
              expanded
              @blur="setAddressToState()"
            >
              <option>0-30</option>
              <option>30-50</option>
              <option>50-100</option>
              <option>>100</option>
            </b-select>
          </b-field>
        </b-field>

        <b-field
          grouped
          position="is-centered"
        >
          <b-field
            :label="$t('oninventory.paragraph29')"
            expanded
          >
            <b-select
              v-model="departure.elevator"
              expanded
              @blur="setAddressToState()"
            >
              <option>{{ $t("oninventory.paragraph27") }}</option>
              <option>{{ $t("oninventory.paragraph26") }}</option>
            </b-select>
          </b-field>
          <b-field
            position="is-centered"
            expanded
          >
            <b-field :label="$t('oninventory.paragraph30')">
              <b-select
                v-model="departure.elevatorUse"
                expanded
                @blur="setAddressToState()"
              >
                <option>{{ $t("oninventory.paragraph27") }}</option>
                <option>{{ $t("oninventory.paragraph26") }}</option>
              </b-select>
            </b-field>
          </b-field>
        </b-field>
      </div>

      <p class="is-size-3 is-size-5-mobile has-text-weight-bold mt-5">
        {{ $t("oninventory.title15") }}
      </p>
      <div class="address-container">
        <b-field
          grouped
          expanded
          :label="$t('oninventory.paragraph19')"
          position="is-centered"
        >
          <b-input
            v-model="destination.address"
            expanded
            @blur="setAddressToState()"
          />
        </b-field>
        <b-field
          grouped
          position="is-centered"
        >
          <b-field :label="$t('oninventory.paragraph25')">
            <b-input
              v-model="destination.floor"
              type="number"
              :controls="false"
              expanded
              @blur="setAddressToState()"
            />
          </b-field>
          <b-field
            :label="$t('oninventory.paragraph28')"
            expanded
          >
            <b-select
              v-model="destination.distance"
              placeholder="Select a distance"
              expanded
              @blur="setAddressToState()"
            >
              <option>0-30</option>
              <option>30-50</option>
              <option>50-100</option>
              <option>>100</option>
            </b-select>
          </b-field>
        </b-field>

        <b-field
          grouped
          position="is-centered"
        >
          <b-field
            :label="$t('oninventory.paragraph29')"
            expanded
          >
            <b-select
              v-model="destination.elevator"
              expanded
              @blur="setAddressToState()"
            >
              <option>{{ $t("oninventory.paragraph27") }}</option>
              <option>{{ $t("oninventory.paragraph26") }}</option>
            </b-select>
          </b-field>
          <b-field
            position="is-centered"
            expanded
          >
            <b-field :label="$t('oninventory.paragraph30')">
              <b-select
                v-model="destination.elevatorUse"
                expanded
                @blur="setAddressToState()"
              >
                <option>{{ $t("oninventory.paragraph27") }}</option>
                <option>{{ $t("oninventory.paragraph26") }}</option>
              </b-select>
            </b-field>
          </b-field>
        </b-field>
        <b-field
          grouped
          position="is-centered"
        >
          <b-field
            position="is-centered"
            expanded
          >
            <b-field
              :label="$t('oninventory.paragraph31')"
              expanded
            >
              <b-input
                v-model="destination.closet"
                type="number"
                :controls="false"
                expanded
                @blur="setAddressToState()"
              />
            </b-field>
          </b-field>
        </b-field>
      </div>
    </card-component>
    <card-component
      v-if="currentRouteName === 'OnTime'"
      class="has-table has-mobile-sort-spaced px-3 py-4 m-3 has-text-centered"
    >
      <p class="is-size-3 is-size-5-mobile has-text-weight-bold">
        {{ $t("oninventory.title15") }}
      </p>
      <div class="address-container is-flex-direction-column">
        <b-field
          grouped
          expanded
          :label="$t('oninventory.paragraph18')"
          position="is-centered"
          class="is-flex-direction-column"
        >
          <b-input
            v-model="departure.address"
            expanded
            @blur="setAddressToState()"
          />
        </b-field>
      </div>
    </card-component>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CardComponent from '@/components/CardComponent'
export default {
  name: 'Address',
  components: {
    CardComponent
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters('inventory', {
      destination: 'getDestination',
      departure: 'getDeparture'
    }),
    currentRouteName () {
      return this.$route.name
    }
  },
  methods: {
    ...mapActions('inventory', ['setAddress']),
    setAddressToState () {
      this.setAddress({
        departure: this.departure,
        destination: this.destination
      })
    }
  }
}
</script>

<style>
.box-4 {
  margin: 20px;
  padding: 20px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.address-container {
  max-width: 700px;
  text-align: center;
  margin: 0 auto;
}

/* .select select:{{ $t('oninventory.paragraph27') }}t([multiple]),
input {
  background-color: #fdfdfb !important;
} */

@media only screen and (max-width: 768px) {
  .address-container .field.is-grouped.is-grouped-centered {
    flex-direction: column !important;
  }
}
</style>
