<template>
  <div style="width: 500px; margin: 50px auto">
    <b-field label="Email" type="is-danger" message="This email is invalid">
      <b-input type="email" value="" maxlength="30" v-model="email"> </b-input>
    </b-field>
    <b-field label="Password">
      <b-input type="password" v-model="password" value="" password-reveal>
      </b-input>
    </b-field>
    <b-button type="is-success" @click="checkLogin()">Login</b-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    checkLogin() {
      if (
        this.email === "claudia.matache@icloud.com" &&
        this.password === ":ws2'LbKSZ9693cW"
      ) {
        localStorage.setItem("jwt", "loggedIn");
        this.$router.push({ name: "admin" });
      }
    },
  },
};
</script>

<style>
</style>