// import axios from '@/axios'

const state = () => ({
  inventoryItems: [
  ],
  inventory: [],
  materials: [],
  materialsTotal: 0,
  activeStep: {
    step: 0,
    title: ''
  },
  starterPrice: 0,
  moveDate: null,
  deliveryDate: null,
  pickupDate: null,
  typeA: {},
  typeB: {},
  cleaningHours: 0,
  packingUnpackingHours: 0,
  workers: 0,
  truck: 0,
  truckNumber: 0,
  hours: 0,
  VAT: 17,
  parkingPermit: {
    departure: {
      status: false,
      price: null
    },
    destination: {
      status: false,
      price: null
    }
  },
  liftAuthorisation: {
    departure: {
      status: false,
      price: null
    },
    destination: {
      status: false,
      price: null
    }
  },
  warranty: {
    status: false,
    price: 99
  },
  cancellation: {
    status: false,
    price: 49
  },
  extElevator: {
    departure: {
      status: false,
      hours: 0
    },
    destination: {
      status: false,
      hours: 0
    }
  },
  departure: {
    address: '',
    floor: 0,
    distance: '',
    elevator: '',
    elevatorUse: null,
    closet: ''
  },
  destination: {
    address: '',
    floor: 0,
    distance: '',
    elevator: 0,
    elevatorUse: null,
    closet: ''
  },
  contact: {
    title: '',
    email: '',
    first_name: '',
    last_name: '',
    phone: ''
  },
  settings: {}
})

// getters
const getters = {
  getState (state) {
    return state
  },
  getActiveStep (state) {
    return state.activeStep
  },
  getInventoryItems (state) {
    return state.inventoryItems
  },
  getInventory (state) {
    return state.inventory
  },
  getStarterPrice (state) {
    return state.starterPrice
  },
  getCleaningHours (state) {
    return state.cleaningHours
  },
  getPackingUnpackingHours (state) {
    return state.packingUnpackingHours
  },
  getMaterials (state) {
    return state.materials
  },
  getWarranty (state) {
    return state.warranty.status
  },
  getElevatorHours (state) {
    return {
      departure: state.extElevator.departure.hours,
      destination: state.extElevator.destination.hours
    }
  },
  getParkingStatus (state) {
    return {
      departure: state.parkingPermit.departure.status,
      destination: state.parkingPermit.destination.status
    }
  },
  getLiftAuthorisation (state) {
    return {
      departure: state.liftAuthorisation.departure.status,
      destination: state.liftAuthorisation.destination.status
    }
  },
  getCancellation (state) {
    return state.cancellation
  },
  getDestination (state) {
    return state.destination
  },
  getDeparture (state) {
    return state.departure
  },
  getContact (state) {
    return state.contact
  },
  getSettings (state) {
    return state.settings
  },
  getInventoryTotal: (state, getters) => {
    return getters.getInventory.reduce((total, item) => {
      return total + item.price * item.quantity
    }, 0)
  },
  getVolumeTotal: (state, getters) => {
    return getters.getInventory.reduce((total, item) => {
      return total + item.volume * item.quantity
    }, 0)
  },
  getDissamblyTotal: (state, getters) => {
    return getters.getInventory.reduce((total, item) => {
      return total + item.disassembly_price * item.disassembly_quantity
    }, 0)
  },
  getMaterialsTotal (state, getters) {
    return getters.getMaterials.reduce((total, item) => {
      return total + item.price * item.quantity
    }, 0)
  },
  getOrderTotal (state, getters) {
    let total = 0
    if (getters.getInventoryTotal) { total += getters.getInventoryTotal }
    if (getters.getDissamblyTotal) { total += getters.getDissamblyTotal }
    if (state.materialsTotal !== 0) { total += state.materialsTotal }
    if (state.cleaningHours !== 0) { total += state.cleaningHours * parseFloat(state.settings.cleaningPerHourCost) }
    if (state.packingUnpackingHours !== 0) { total += state.packingUnpackingHours * parseFloat(state.settings.packingUnpackingPerHourCost) }
    if (state.activeStep.step >= 5 && state.activeStep.title === 'OnTime') {
      if (state.parkingPermit.destination.status) { total += state.parkingPermit.destination.price }
      if (state.parkingPermit.departure.status) { total += state.parkingPermit.departure.price }
    }
    if (state.activeStep.step >= 6 && state.activeStep.title === 'OnInventory') {
      if (state.parkingPermit.destination.status) { total += state.parkingPermit.destination.price }
      if (state.parkingPermit.departure.status) { total += state.parkingPermit.departure.price }
    }
    if (state.distanceFromOffice !== 0) {
      // total += state.departure.closet * state.settings.distanceFromOffice
      total += state.destination.closet * state.settings.distanceFromOffice
    }

    if (state.cancellation.status === true) {
      total += parseFloat(state.cancellation.price)
    }

    if (state.warranty.status === true) { total += parseFloat(state.warranty.price) }

    if (state.activeStep.step >= 6 && state.activeStep.title === 'OnTime') {
      total += parseFloat(state.settings.warrantyDefault)
    }

    if (state.activeStep.step >= 8 && state.activeStep.title === 'OnInventory') {
      total += parseFloat(state.settings.warrantyDefault)
    }

    if (state.departure.distance === '30-50') { total += parseFloat(state.settings.distance30) }
    if (state.departure.distance === '50-100') { total += parseFloat(state.settings.distance50) }
    if (state.departure.distance === '>100') { total += parseFloat(state.settings.distance100) }

    if (state.departure.floor !== 0 && state.departure.elevatorUse === 'No' && state.departure.elevator === 'No') {
      total += state.departure.floor * parseFloat(state.settings.floorTax)
    }

    if (state.destination.distance === '30-50') { total += parseFloat(state.settings.distance30) }
    if (state.destination.distance === '50-100') { total += parseFloat(state.settings.distance50) }
    if (state.destination.distance === '>100') { total += parseFloat(state.settings.distance100) }

    if (state.destination.floor !== 0 && state.destination.elevatorUse === 'No' && state.destination.elevator === 'No') {
      total += state.destination.floor * parseFloat(state.settings.floorTax)
    }

    if (state.extElevator.departure.status === true) { total += parseFloat(state.settings.extElevatorTax) + parseFloat(state.settings.extElevator) * state.extElevator.departure.hours }
    if (state.extElevator.destination.status === true) { total += parseFloat(state.settings.extElevatorTax) + parseFloat(state.settings.extElevator) * state.extElevator.destination.hours }

    if (state.workers !== 0 && state.hours !== 0) {
      if (state.hours === 2) {
        total += state.workers * parseFloat(state.settings.OnTime2h * state.hours)
      }
      if (state.hours === 4) {
        total += state.workers * parseFloat(state.settings.OnTime4h * state.hours)
      }
      if (state.hours === 8) {
        total += state.workers * parseFloat(state.settings.OnTime8h * state.hours)
      }
    }

    if (state.truck === 12) total += parseFloat(state.settings.truck12)
    if (state.truck === 22) {
      if (state.truckNumber == 2) {
        total += parseFloat(state.settings.truck22) * 2
      } else {
        total += parseFloat(state.settings.truck22)
      }
    }
    if (state.truck === 40) total += parseFloat(state.settings.truck40)
    if (isNaN(total)) {
      return 0
    }
    // total += total * state.VAT / 100

    return total
  },
  getVAT (state, getters) {
    return (getters.getOrderTotal * parseFloat(state.settings.VAT) / 100)
  },
  getOrderTotalInclTax (state, getters) {
    return getters.getOrderTotal + (getters.getOrderTotal * parseFloat(state.settings.VAT) / 100)
  }
}

// actions
const actions = {
  addItemToInventory ({ state, commit }, item) {
    const inventoryItem = state.inventory.find(element => element.id === item.id)
    if (!inventoryItem) {
      // commit('addInventoryItem', { id: item.id, name: item.name, volume: item.volume, price: item.price, icon: item.icon, quantity: 1 })
      commit('addInventoryItem', { ...item, quantity: 1 })
    } else {
      commit('incrementItemQuantity', inventoryItem)
    }
  },
  updateActiveStep ({ commit }, step) {
    commit('updateActiveStep', step)
  },
  setItemQuantity ({ commit }, item) {
    commit('setItemQuantity', item)
  },
  incrementItemQuantity ({ commit }, id) {
    commit('incrementItemQuantity', id)
  },
  decrementItemQuantity ({ commit }, id) {
    commit('decrementItemQuantity', id)
  },
  incrementItemDissamblyQuantity ({ commit }, id) {
    commit('incrementItemDissamblyQuantity', id)
  },
  decrementItemDissamblyQuantity ({ commit }, id) {
    commit('decrementItemDissamblyQuantity', id)
  },
  deleteItemFromInventory ({ commit }, id) {
    commit('deleteItemFromInventory', id)
  },
  setMoveDate ({ commit }, date) {
    commit('setMoveDate', date)
  },
  setDeliveryDate ({ commit }, date) {
    commit('setDeliveryDate', date)
  },
  setPickupDate ({ commit }, date) {
    commit('setPickupDate', date)
  },
  setTypeA ({ commit }, item) {
    commit('setTypeA', item)
  },
  setTypeB ({ commit }, item) {
    commit('setTypeB', item)
  },
  setItemMaterialQuantity ({ commit }, item) {
    commit('setItemMaterialQuantity', item)
  },
  setParkingPermit ({ commit }, item) {
    commit('setParkingPermit', item)
  },
  setLiftAuthorisation ({ commit }, item) {
    commit('setLiftAuthorisation', item)
  },
  setWarranty ({ commit }, item) {
    commit('setWarranty', item)
  },
  setCancellation ({ commit }, item) {
    commit('setCancellation', item)
  },
  setCleaningHours ({ commit }, item) {
    commit('setCleaningHours', item)
  },
  setExtElevatorHours ({ commit }, item) {
    commit('setExtElevatorHours', item)
  },
  setWorkers ({ commit }, item) {
    commit('setWorkers', item)
  },
  setTruck ({ commit }, item) {
    commit('setTruck', item)
  },
  setTruckNumber ({ commit }, item) {
    commit('setTruckNumber', item)
  },
  setHours ({ commit }, item) {
    commit('setHours', item)
  },
  setPackingUnpackingHours ({ commit }, item) {
    commit('setPackingUnpackingHours', item)
  },
  setAddress ({ commit }, item) {
    commit('setAddress', item)
  },
  setContact ({ commit }, item) {
    commit('setContact', item)
  },
  setSettings ({ commit }, item) {
    commit('setSettings', item)
  },
  setMaterials ({ commit }, item) {
    commit('setMaterials', item)
  },
  resetState ({ commit }) {
    commit('resetState')
  }
}

// mutations
const mutations = {
  updateActiveStep (state, step) {
    state.activeStep = step
  },
  setMoveDate (state, date) {
    state.moveDate = date
  },
  setDeliveryDate (state, date) {
    state.deliveryDate = date
  },
  setPickupDate (state, date) {
    state.pickupDate = date
  },
  deleteItemFromInventory (state, { id }) {
    const inventoryItemIndex = state.inventory.findIndex(item => item.id === id)
    state.inventory.splice(inventoryItemIndex, 1)
  },
  setItemQuantity (state, item) {
    const inventoryItem = state.inventory.find(element => element.id === item.id)
    inventoryItem.quantity = item.quantity
  },
  incrementItemQuantity (state, { id }) {
    const inventoryItem = state.inventory.find(item => item.id === id)
    inventoryItem.quantity++
  },
  decrementItemQuantity (state, { id }) {
    const inventoryItem = state.inventory.find(item => item.id === id)
    if (inventoryItem.quantity > 1) { inventoryItem.quantity-- }
  },
  incrementItemDissamblyQuantity (state, { id }) {
    const inventoryItem = state.inventory.find(item => item.id === id)
    if (inventoryItem.quantity > inventoryItem.disassembly_quantity) { inventoryItem.disassembly_quantity++ }
  },
  decrementItemDissamblyQuantity (state, { id }) {
    const inventoryItem = state.inventory.find(item => item.id === id)
    if (inventoryItem.disassembly_quantity >= 1) { inventoryItem.disassembly_quantity-- }
  },
  addInventoryItem (state, payload) {
    state.inventory.push(payload)
  },
  updateInventoryItem (state, payload, id) {
    const itemIndex = state.inventory.findIndex(obj => { return obj.id === id })
    state.inventory[itemIndex] = payload
  },
  setTypeA (state, payload) {
    state.typeA = { ...payload }
  },
  setTypeB (state, payload) {
    state.typeB = { ...payload }
  },
  setItemMaterialQuantity (state, item) {
    const materialItem = state.materials.find(element => element.id === item.id)
    materialItem.quantity = item.quantity
    const materials = [...state.materials]
    const total = materials.reduce((total, item) => {
      return total + item.price * item.quantity
    }, 0)
    state.materialsTotal = total
  },
  setParkingPermit (state, payload) {
    state.parkingPermit = { ...payload }
  },
  setLiftAuthorisation (state, payload) {
    state.liftAuthorisation = { ...payload }
  },
  setWarranty (state, payload) {
    state.warranty = payload
  },
  setCancellation (state, payload) {
    state.cancellation.status = payload
  },
  setCleaningHours (state, payload) {
    state.cleaningHours = payload
  },
  setExtElevatorHours (state, payload) {
    state.extElevator = { ...payload }
  },
  setWorkers (state, payload) {
    state.workers = payload
  },
  setTruck (state, payload) {
    state.truck = payload
  },
  setTruckNumber (state, payload) {
    state.truckNumber = payload
  },
  setHours (state, payload) {
    state.hours = payload
  },
  setPackingUnpackingHours (state, payload) {
    state.packingUnpackingHours = payload
  },
  setAddress (state, payload) {
    state.departure = { ...payload.departure }
    state.destination = { ...payload.destination }
  },
  setContact (state, payload) {
    state.contact = { ...payload.contact }
  },
  setSettings (state, payload) {
    state.settings = { ...payload }
    state.cancellation.price = payload.cancellationPrice
    state.warranty.price = payload.warrantyPrice
    state.starterPrice = parseFloat(payload.starterPrice)
    state.VAT = parseFloat(payload.VAT)
    state.parkingPermit.destination.price = parseFloat(payload.parkingPermitPrice)
    state.parkingPermit.departure.price = parseFloat(payload.parkingPermitPrice)
  },
  setMaterials (state, payload) {
    state.materials = [...payload]
  },
  resetState (state) {
    state = {
      inventoryItems: [
      ],
      inventory: [],
      materials: [],
      materialsTotal: 0,
      starterPrice: 0,
      moveDate: null,
      deliveryDate: null,
      pickupDate: null,
      typeA: {},
      typeB: {},
      cleaningHours: 0,
      packingUnpackingHours: 0,
      workers: 0,
      truck: 0,
      truckNumber: 0,
      hours: 0,
      warranty: {
        status: false,
        price: 99
      },
      cancellation: {
        status: false,
        price: 49
      },
      extElevator: {
        departure: {
          status: false,
          hours: 0
        },
        destination: {
          status: false,
          hours: 0
        }
      },
      departure: {
        address: '',
        postalCode: null,
        city: '',
        type: '',
        area: 0,
        floor: 0,
        distance: '',
        elevator: '',
        elevatorUse: null,
        closet: ''
      },
      destination: {
        address: '',
        postalCode: null,
        city: '',
        type: '',
        area: 0,
        floor: 0,
        distance: '',
        elevator: 0,
        elevatorUse: null,
        closet: ''
      },
      contact: {
        title: '',
        email: '',
        first_name: '',
        last_name: '',
        phone: ''
      },
      settings: {}
    }
    localStorage.removeItem('vuex')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
