<template>
  <div>
    <h2
      class="has-text-centered has-text-weight-bold is-size-2 is-size-4-mobile is-uppercase mb-6"
    >
      {{ $t("oninventory.title7") }}
    </h2>
    <card-component class="has-table has-mobile-sort-spaced px-6 py-4 m-3">
      <div class="box-container">
        <b-image
          :src="require('@/assets/package.png')"
          alt="packing unpacking"
          class="service-icon"
        />
        <b-field label="How many hours?">
          <b-numberinput
            v-model="packingUnpacking"
            :controls="false"
            @input="packingUnpackingHours()"
          />
        </b-field>
        <p class="ml-5 has-text-weight-bold">
          {{ settings.packingUnpackingPerHourCost }}€/h
        </p>
      </div>
    </card-component>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CardComponent from '@/components/CardComponent'
export default {
  name: 'PackingAndUnpacking',
  components: {
    CardComponent
  },
  data () {
    return {
      packingUnpacking: 0
    }
  },
  computed: {
    ...mapGetters('inventory', {
      settings: 'getSettings'
    })
  },
  methods: {
    ...mapActions('inventory', ['setPackingUnpackingHours']),
    packingUnpackingHours () {
      this.setPackingUnpackingHours(this.packingUnpacking)
    }
  }
}
</script>

<style scoped>
.box-4 {
  margin: 20px;
  padding: 20px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.box-container {
  max-width: 600px;
  display: flex;
  text-align: center;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}
</style>
